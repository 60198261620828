import { Button, Checkbox, FormControl, OutlinedInput } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { selectUser } from "../../slices/user";
import api, {
  CreateNetworkGroupRequest,
  NGAccessServers,
  UnitPayload,
} from "../../api";
import { showError } from "../../utils/helper";
import { StrapiAccessPointData } from "../../strapiModels/strapiAccessPoints";
import { selectStrapiProperty } from "../../slices/strapiProperty";

interface AdminDesktopEditDepartmentProps {
  id: number | null;
  name: string;
  array: StrapiAccessPointData[] | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCloseFunction: () => void;
}

const AdminDesktopEditDepartment = ({
  id,
  name,
  array,
  open,
  setOpen,
  onCloseFunction,
}: AdminDesktopEditDepartmentProps) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const property = useAppSelector(selectStrapiProperty);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [departmentName, setDepartmentName] = React.useState<string>("");
  const [accessPointsArray, setAccessPointArray] = React.useState<
    { venue_name: string; access: { name: string; id: number }[] }[] | null
  >(null);
  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);

  const disabledButton =
    !departmentName || finalAccessPointTemporary.length < 1;

  React.useEffect(() => {
    getInitialStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getInitialStates = () => {
    if (open) {
      if (name) {
        setDepartmentName(name);
      }
      if (array && array.length > 0) {
        const newArray = array.map((element) => element.id);
        setFinalAccessPointTemporary(newArray);
      }
    } else {
      setDepartmentName("");
      setFinalAccessPointTemporary([]);
    }
  };

  const handleCheckboxChange = (id: number) => {
    if (finalAccessPointTemporary.includes(id)) {
      setFinalAccessPointTemporary(
        finalAccessPointTemporary.filter((item) => item !== id)
      );
    } else {
      setFinalAccessPointTemporary([...finalAccessPointTemporary, id]);
    }
  };

  React.useEffect(() => {
    getAccessPointsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const getAccessPointsArray = async () => {
    if (property) {
      try {
        setIsLoading(true);
        const response = await api.strapiAccessPointsByPropertyRequest(
          property?.id
        );
        if (response) {
          const nuevoArreglo: any = [];
          for (const obj of response.data) {
            const { attributes } = obj;

            const index = nuevoArreglo.findIndex(
              (item: any) => item.venue_name === attributes?.vanue_name
            );

            if (index !== -1) {
              nuevoArreglo[index].access.push({
                name: attributes?.name,
                id: obj.id,
              });
            } else {
              nuevoArreglo.push({
                venue_name: attributes?.vanue_name,
                access: [{ name: attributes?.name, id: obj.id }],
              });
            }
          }
          setAccessPointArray(nuevoArreglo);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const getAccessPointsArray = () => {
  //   const nuevoArreglo: any = [];
  //   const array1 = user?.access_servers;

  //   if (array1) {
  //     for (const obj of array1) {
  //       const { name, venue_name } = obj;

  //       const index = nuevoArreglo.findIndex(
  //         (item: any) => item.venue_name === venue_name
  //       );

  //       if (index !== -1) {
  //         nuevoArreglo[index].access.push({ name, id: obj.id });
  //       } else {
  //         nuevoArreglo.push({ venue_name, access: [{ name, id: obj.id }] });
  //       }
  //     }
  //     setAccessPointArray(nuevoArreglo);
  //   }
  // };

  const onUpdateDepartment = async () => {
    if (id) {
      const payload: UnitPayload = {
        data: {
          access_points: finalAccessPointTemporary,
          name: departmentName,
          property: property?.id,
        },
      };
      try {
        setIsLoading(true);
        const response = await api.strapiUpdateUnit(id, payload);
        if (response) {
          setOpen(false);
          onCloseFunction();
        }
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const onUpdateDepartment = async () => {
  //   if (id) {
  //     const payload: CreateNetworkGroupRequest = {
  //       network_group: {
  //         access_servers: finalAccessPointTemporary,
  //         name: departmentName,
  //       },
  //     };
  //     try {
  //       setIsLoading(true);
  //       const response = await api.updateNetworkGroup(id, payload);
  //       if (response) {
  //         setOpen(false);
  //         onCloseFunction();
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       showError(dispatch, labels.generic_error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: 600,
          height: `calc(100% - 99.5px)`,
          boxSizing: "border-box",
          position: "relative",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            // display: "flex",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          }}
        >
          <div style={{ width: "100%" }}>
            {/* <p style={{ fontSize: 18, marginLeft: 20, marginBottom: 25 }}>
              {labels.unit_info}
            </p> */}
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: 4,
                boxSizing: "border-box",
                padding: 30,
                // borderColor: "rgba(196, 196, 196, 0.5)",
                // borderWidth: 1,
                // borderStyle: "solid",
                // boxShadow:
                //   "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                boxShadow:
                  "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <div style={{ width: "100%" }}>
                {/* <TextField
                  required
                  value={addName}
                  label={labels.full_name}
                  fullWidth
                  onChange={(e) => {
                    setAddName(e.target.value);
                  }}
                /> */}
                <p
                  className="title-color"
                  style={{ marginBottom: 10, padding: 0 }}
                >
                  Nombre de la unidad *
                </p>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined"
                    placeholder={name ? name : labels.name}
                    type="text"
                    value={departmentName}
                    onChange={(e) => {
                      setDepartmentName(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <div>
                  <div style={{ width: "100%", marginBottom: 15 }}>
                    <p
                      className="title-color"
                      style={{ marginBottom: 10, padding: 0 }}
                    >
                      Puntos de acceso permitidos *
                    </p>
                    <div
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        // borderRadius: 4,
                        // borderWidth: 1,
                        // borderStyle: "solid",
                        // borderColor: "#c4c4c4",
                        // padding: 10,
                      }}
                    >
                      {accessPointsArray &&
                        accessPointsArray.length > 0 &&
                        accessPointsArray.map(
                          ({ access, venue_name }, index) => (
                            <div
                              key={index}
                              className="w-100 flex"
                              style={{
                                alignItems: "flex-start",
                                borderRadius: 4,
                                borderWidth: 1,
                                boxSizing: "border-box",
                                borderStyle: "solid",
                                borderColor: "#c4c4c4",
                                padding: 10,
                                marginBottom:
                                  index === accessPointsArray.length - 1
                                    ? 0
                                    : 10,
                              }}
                            >
                              <div className="w-50">
                                <p className="title-color desktop-tables-font m-0 p-0 mt-5">
                                  {venue_name}
                                </p>
                              </div>
                              <div className="w-50">
                                {access.map(({ name, id }) => (
                                  <div
                                    key={id}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      onChange={() => handleCheckboxChange(id)}
                                      checked={finalAccessPointTemporary.includes(
                                        id
                                      )}
                                      color="secondary"
                                      disabled={isLoading ? true : false}
                                    />
                                    {/* <Checkbox
                                      onChange={() => {
                                        let elementFind =
                                          finalAccessPointTemporary.find(
                                            (element) => element === id
                                          );
                                        let finalIndex =
                                          finalAccessPointTemporary.indexOf(id);
                                        const finalAccessPointTemporaryClone = [
                                          ...finalAccessPointTemporary,
                                        ];
                                        if (!elementFind) {
                                          finalAccessPointTemporaryClone.push(
                                            id
                                          );
                                        } else {
                                          finalAccessPointTemporaryClone.splice(
                                            finalIndex,
                                            1
                                          );
                                        }
                                        setFinalAccessPointTemporary(
                                          finalAccessPointTemporaryClone
                                        );
                                      }}
                                      color="secondary"
                                    /> */}
                                    <p
                                      key={id}
                                      className="desktop-tables-font"
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      {name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "flex-end",
                // padding: 40,
                paddingTop: 40,
                paddingBottom: 40,
                // marginBottom: 40,
              }}
            >
              <Button
                // fullWidth
                variant="contained"
                size="large"
                style={{ height: 56, width: 400 }}
                disabled={disabledButton}
                onClick={onUpdateDepartment}
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDesktopEditDepartment;
