import { Button, Container, Paper, TextField } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

const UserRegister = () => {
  const labels = useAppSelector(selectLabels);

  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");

  const disabled = !password || !confirmPassword;

  return (
    <Container maxWidth="xs" className="mt-7">
      <Paper
        variant="outlined"
        className="p-10 vh-85 flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
      >
        <Logo type="passtrackLogo" alt="passtrack" className="logo" />
        <p className="text-center subtitle-color medium font-22  mt-0">
          {labels.register}
        </p>
        <p className="text-center secondary-color pb-5">
          {labels.register_text}
        </p>
        <TextField
          value={password}
          label={labels.your_email}
          variant="outlined"
          fullWidth
          placeholder="john_smith@gmail.com"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        ></TextField>
        <TextField
          value={confirmPassword}
          label={labels.your_email}
          variant="outlined"
          fullWidth
          placeholder="john_smith@gmail.com"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          className="py-6"
          onClick={() => {}}
          disabled={disabled}
        >
          {labels.continue}
        </Button>
      </Paper>
    </Container>
  );
};

export default UserRegister;
