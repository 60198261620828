import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Button,
  Switch,
  TextField,
  Modal,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import { resetUser, selectUser } from "../../slices/user";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate, useParams } from "react-router-dom";
import "./new-invitation.scss";
import api, {
  CommunityMembers,
  CommunityMembersList,
  CreateAccessKeyParams,
  Providers,
} from "../../api";
import { showError } from "../../utils/helper";
import CreateIcon from "@mui/icons-material/Create";
import { Box } from "@mui/system";
import { resetToken, selectToken } from "../../slices/token";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import CustomAttributes from "../../components/CustomAttributes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const NewInvitation = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const defaultAntipassbackValue = user
    ? user?.network?.passback_activated_by_default !== null
      ? user?.network?.passback_activated_by_default
      : true
    : true;
  const disabledAntipassback =
    user?.network?.block_antipassback !== null
      ? user?.network?.block_antipassback
      : false;

  const [temporaryInvitation, setTemporaryInvitation] =
    React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [temporaryDate, setTemporaryDate] = React.useState<Date | null>(
    new Date()
  );
  const [timeDuration, setTimeDuration] = React.useState<string>("days");
  const [duration, setDuration] = React.useState<string>("1");
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [hourTemporary, setHourTemporary] = React.useState<string>(
    moment(date).format("HH")
  );
  const [minutesTemporary, setMinutesTemporary] = React.useState<string>("00");
  const [hoursLaterTemporary, setHoursLaterTemporary] =
    React.useState<string>("1");
  const [addName, setAddName] = React.useState<string>("");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [reasonForVisit, setReasonForVisit] = React.useState<string>("");
  const [remark, setRemark] = React.useState<string>("");
  const [checked, setChecked] = React.useState(false);
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [showRestrictions, setShowRestrictions] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [mondayCheck, setMondayCheck] = React.useState<boolean>(false);
  const [tuesdayCheck, setTuesdayCheck] = React.useState<boolean>(false);
  const [wednesdayCheck, setWednesdayCheck] = React.useState<boolean>(false);
  const [thursdayCheck, setThursdayCheck] = React.useState<boolean>(false);
  const [fridayCheck, setFridayCheck] = React.useState<boolean>(false);
  const [saturdayCheck, setSaturdayCheck] = React.useState<boolean>(false);
  const [sundayCheck, setSundayCheck] = React.useState<boolean>(false);
  const [antiPassback, setAntiPassback] = React.useState<boolean>(
    defaultAntipassbackValue
  );
  const [checkedAntiPassback, setCheckedAntiPassback] = React.useState<boolean>(
    defaultAntipassbackValue
  );
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<boolean>(false);
  const [communityMembersList, setCommunityMembersList] = React.useState<
    string[]
  >([]);
  const [communityMember, setCommunityMemberVar] = React.useState<
    string | null
  >(null);
  const [filteredCommunityMemberName, setFilteredCommunityMemberName] =
    React.useState<string | null>("");
  const [filteredCommunityMemberPhone, setFilteredCommunityMemberPhone] =
    React.useState<string | null>("");
  const [filteredCommunityMemberEmail, setFilteredCommunityMemberEmail] =
    React.useState<string | null>("");
  const [
    filteredCommunityMemberPhoneCode,
    setFilteredCommunityMemberPhoneCode,
  ] = React.useState<string | null>("+52");
  const [filteredCommunityMemberId, setFilteredCommunityMemberId] =
    React.useState<Number | null>(null);
  const [inputAutocompleteCM, setInputAutocompleteCM] =
    React.useState<string>("");
  const [isProvider, setIsProvider] = React.useState<string>("visit");

  const networkName = user?.network_group?.name;

  const [communityMembers, setCommunityMembers] =
    React.useState<CommunityMembersList | null>(null);

  const [providersList, setProvidersList] = React.useState<string[]>([]);
  const [provider, setProvider] = React.useState<string | null>("");
  const [fullProvidersList, setFullProvidersList] =
    React.useState<Providers | null>(null);
  const [addProvider, setAddProvider] = React.useState<string>("");
  const [temporaryHelpModal, setTemporaryHelpModal] =
    React.useState<boolean>(false);
  const [invitationCustomAttributes, setInvitationCustomAttributes] =
    React.useState<any | null>(null);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = invitationCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsProvider((event.target as HTMLInputElement).value);
    if (temporaryInvitation === true) {
      setTemporaryInvitation(false);
    }
    if ((event.target as HTMLInputElement).value === "visit") {
      setProvider("");
    }
    setHoursLater("1");
    setDate(new Date());
    setReasonForVisit("");
    setRemark("");
    setHour(moment(date).format("HH"));
    getInitialMinutes();
  };

  const getCommunityMemberData = () => {
    if (communityMember !== null) {
      const filteredCommunityMemberInv: CommunityMembers[] | undefined =
        communityMembers?.community_members.filter(
          ({ name }) => name === communityMember
        );
      if (filteredCommunityMemberInv) {
        setFilteredCommunityMemberId(filteredCommunityMemberInv[0]?.id);
        setFilteredCommunityMemberName(filteredCommunityMemberInv[0]?.name);
        setFilteredCommunityMemberEmail(filteredCommunityMemberInv[0]?.email);
        if (filteredCommunityMemberInv[0].phone.startsWith("+52")) {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0]?.phone.slice(3)
          );
          setFilteredCommunityMemberPhoneCode("+52");
        } else {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0]?.phone.slice(2)
          );
          setFilteredCommunityMemberPhoneCode("+1");
        }
      }
    } else {
      setFilteredCommunityMemberName("");
      setFilteredCommunityMemberEmail("");
      setFilteredCommunityMemberPhone("");
      setFilteredCommunityMemberPhoneCode("+52");
    }
  };

  React.useEffect(() => {
    getCommunityMemberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityMember]);

  const onChangeSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setTemporaryInvitation(event.target.checked);
    if (temporaryInvitation === false) {
      setFinalAccessPointTemporary([]);
      setMondayCheck(false);
      setTuesdayCheck(false);
      setWednesdayCheck(false);
      setThursdayCheck(false);
      setFridayCheck(false);
      setSaturdayCheck(false);
      setSundayCheck(false);
      setHoursLaterTemporary("1");
      setHourTemporary(moment(date).format("HH"));
      setMinutesTemporary("00");
      setTemporaryDate(new Date());
      setTimeDuration("days");
      setDuration("1");
    } else {
      setHour(moment(date).format("HH"));
      setDate(new Date());
      setHoursLater("1");
      getInitialMinutes();
    }
  };

  React.useEffect(() => {
    getInitialMinutes();
  }, []);

  const getInitialMinutes = () => {
    if (
      moment(new Date()).format("mm") >= "00" &&
      moment(new Date()).format("mm") < "15"
    ) {
      setMinutes("00");
    } else if (
      moment(new Date()).format("mm") >= "15" &&
      moment(new Date()).format("mm") < "30"
    ) {
      setMinutes("15");
    } else if (
      moment(new Date()).format("mm") >= "30" &&
      moment(new Date()).format("mm") < "45"
    ) {
      setMinutes("30");
    } else {
      setMinutes("45");
    }
  };

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const handleAntiPassbackChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checkedAntiPassback) {
      setAntiPassback(true);
    } else {
      setAntiPassback(false);
    }
    setCheckedAntiPassback(event.target.checked);
  };

  const onClickMonday = () => {
    setMondayCheck(!mondayCheck);
  };
  const onClickTuesday = () => {
    setTuesdayCheck(!tuesdayCheck);
  };
  const onClickWednesday = () => {
    setWednesdayCheck(!wednesdayCheck);
  };
  const onClickThursday = () => {
    setThursdayCheck(!thursdayCheck);
  };
  const onClickFriday = () => {
    setFridayCheck(!fridayCheck);
  };
  const onClickSaturday = () => {
    setSaturdayCheck(!saturdayCheck);
  };
  const onClickSunday = () => {
    setSundayCheck(!sundayCheck);
  };

  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;

  const isoDateTemporary = `${moment(temporaryDate).format(
    `YYYY-MM-DDT${hourTemporary === "--" ? "00" : hourTemporary}:${
      minutesTemporary === "--" ? "00" : minutesTemporary
    }:ss.SSSZ`
  )}`;

  const daysArray = () => {
    const daysRestriction: any = [];
    if (mondayCheck) {
      daysRestriction.push(1);
    }
    if (tuesdayCheck) {
      daysRestriction.push(2);
    }
    if (wednesdayCheck) {
      daysRestriction.push(3);
    }
    if (thursdayCheck) {
      daysRestriction.push(4);
    }
    if (fridayCheck) {
      daysRestriction.push(5);
    }
    if (saturdayCheck) {
      daysRestriction.push(6);
    }
    if (sundayCheck) {
      daysRestriction.push(0);
    }
    return daysRestriction;
  };

  const temporaryTimeRestriction = hourTemporary + ":" + minutesTemporary;

  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);

  const { cmId } = useParams();

  React.useEffect(() => {
    if (networkName) {
      getCommunityMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkName]);

  const getCommunityMembers = async () => {
    if (networkName) {
      try {
        const result = await api.getCommunityMembersList(networkName);
        const cmName = Array.from(
          new Set(result.community_members.map(({ name }) => name))
        );
        setCommunityMembers(result);
        setCommunityMembersList(cmName);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  React.useEffect(() => {
    if (token) {
      getProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getProviders = async () => {
    if (token) {
      try {
        const result = await api.providersRequest();
        const providersName = Array.from(
          new Set(result.providers.map(({ display_name }) => display_name))
        );
        setFullProvidersList(result);
        setProvidersList(providersName);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.wrong_parameters);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  React.useEffect(() => {
    finalProvidersArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersList]);

  const finalProvidersArray = () => {
    if (providersList) {
      let newArray: any = [];
      for (var i = 0; i < providersList.length; i++) {
        if (providersList[i] === "Otro") {
          providersList.splice(i, 1);
        }
      }
      newArray = providersList.sort();
      newArray.push("Otro");
      setProvidersList(newArray);
    }
  };

  const filteredProvider = fullProvidersList?.providers.filter(
    ({ display_name }) => display_name === provider
  );

  const filteredCommunityMember = communityMembers?.community_members.filter(
    ({ id }) => id === Number(cmId)
  );

  const communityMemberName =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0]?.name;
  const communityMemberFinalCountryCodeMX =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0].phone.startsWith("+52") &&
    filteredCommunityMember[0].phone.slice(0, 3);
  const communityMemberFinalCountryCodeUS =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0].phone.startsWith("+1") &&
    filteredCommunityMember[0].phone.slice(0, 2);
  const communityMemberPhoneMX =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0].phone.startsWith("+52") &&
    filteredCommunityMember[0].phone.slice(3);
  const communityMemberPhoneUS =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0].phone.startsWith("+1") &&
    filteredCommunityMember[0].phone.slice(2);
  const communityMemberEmail =
    cmId !== ":cmId" &&
    filteredCommunityMember &&
    filteredCommunityMember[0].email;

  const isDisabledProvider =
    filteredProvider?.length === 0 ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0;

  const isDisabledProviderOther =
    filteredProvider?.length === 0 ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0 ||
    !addProvider;

  const isDisabledVisitorCmId =
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0;

  const isDisabledVisitor =
    !addName ||
    !countryCode ||
    !addPhone ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0;

  const isDisabledTemporaryCmId =
    !reasonForVisit ||
    !timeDuration ||
    !duration ||
    !isoDateTemporary ||
    finalAccessPointTemporary.length === 0;

  const isDisabledTemporary =
    !addName ||
    !countryCode ||
    !addPhone ||
    !reasonForVisit ||
    !timeDuration ||
    !duration ||
    !isoDateTemporary ||
    finalAccessPointTemporary.length === 0;

  const isDisabledProviderCA =
    filteredProvider?.length === 0 ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0 ||
    !customAttributesRequired;

  const isDisabledProviderOtherCA =
    filteredProvider?.length === 0 ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0 ||
    !addProvider ||
    !customAttributesRequired;

  const isDisabledVisitorCmIdCA =
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0 ||
    !customAttributesRequired;

  const isDisabledVisitorCA =
    !addName ||
    !countryCode ||
    !addPhone ||
    !reasonForVisit ||
    !hoursLater ||
    !isoDate ||
    finalAccessPointTemporary.length === 0 ||
    !customAttributesRequired;

  const isDisabledTemporaryCmIdCA =
    !reasonForVisit ||
    !timeDuration ||
    !duration ||
    !isoDateTemporary ||
    finalAccessPointTemporary.length === 0 ||
    !customAttributesRequired;

  const isDisabledTemporaryCA =
    !addName ||
    !countryCode ||
    !addPhone ||
    !reasonForVisit ||
    !timeDuration ||
    !duration ||
    !isoDateTemporary ||
    finalAccessPointTemporary.length === 0 ||
    !customAttributesRequired;

  const generateAccessKey = async () => {
    const payload: CreateAccessKeyParams = {
      access_key: {
        visitor_name:
          cmId !== ":cmId"
            ? `${communityMemberName}`
            : communityMember !== null
            ? `${filteredCommunityMemberName}`
            : isProvider === "provider"
            ? provider === "Otro"
              ? addProvider
              : ""
            : addName,
        visitor_phone:
          cmId !== ":cmId"
            ? filteredCommunityMember
              ? filteredCommunityMember[0].phone.startsWith("+1")
                ? `${communityMemberPhoneUS}`
                : `${communityMemberPhoneMX}`
              : ""
            : communityMember !== null
            ? `${filteredCommunityMemberPhone}`
            : addPhone,
        visitor_email:
          cmId !== ":cmId"
            ? `${communityMemberEmail}`
            : communityMember !== null
            ? `${filteredCommunityMemberEmail}`
            : addEmail,
        country_code:
          cmId !== ":cmId"
            ? filteredCommunityMember
              ? filteredCommunityMember[0].phone.startsWith("+1")
                ? `${communityMemberFinalCountryCodeUS}`
                : `${communityMemberFinalCountryCodeMX}`
              : ""
            : communityMember !== null
            ? `${filteredCommunityMemberPhoneCode}`
            : countryCode,
        access_key_type_id: temporaryInvitation
          ? 2
          : isProvider === "provider"
          ? 5
          : 1,
        reason_for_visit: remark,
        notes: reasonForVisit,
        visitor_duration: temporaryInvitation ? "" : hoursLater,
        duration: !temporaryInvitation ? "" : timeDuration,
        temporary_duration: !temporaryInvitation ? "" : duration,
        valid_from: temporaryInvitation
          ? moment(isoDateTemporary).format("DD/MM/YYYY HH:mm:ss")
          : moment(isoDate).format("DD/MM/YYYY HH:mm:ss"),
        community_member_id:
          cmId !== ":cmId"
            ? `${cmId}`
            : communityMember !== null
            ? `${filteredCommunityMemberId}`
            : null,
        access_servers: finalAccessPointTemporary,
        save_community_member: saveCommunityMember,
      },
    };
    if (invitationCustomAttributes) {
      const customAttributes: any = {};
      invitationCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
        }
      );
      payload.access_key.custom_attrs = customAttributes;
    }
    if (temporaryInvitation) {
      payload.access_key.passback_tracking = antiPassback;
    }
    if (showRestrictions) {
      payload.access_key.key_restrictions = {
        days: daysArray().length > 0 ? daysArray() : [1, 2, 3, 4, 5, 6, 0],
        time:
          temporaryTimeRestriction !== "--:--"
            ? temporaryTimeRestriction
            : "00:00",
        duration_in_minutes:
          hoursLaterTemporary !== "--" ? Number(hoursLaterTemporary) : 24,
      };
    }
    if (isProvider === "provider") {
      payload.access_key.provider = filteredProvider
        ? filteredProvider[0]?.name
        : "";
    }
    try {
      setIsLoading(true);
      await api.createAccessKey(payload);
      setShowSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      console.log("error", error.response);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 404) {
        showError(dispatch, labels.not_found);
      } else if (error.response.status === 422) {
        showError(dispatch, error.response.data.error.message[0]);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getInvitationCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getInvitationCustomAttributes = async () => {
    if (token) {
      try {
        const result = await api.getInvitationCustomAttributes();
        setInvitationCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  return (
    <>
      <Container>
        <div className="w-100">
          <Grid container className="flex align-items-center pt-5">
            <Grid
              item
              xs={12}
              md={6}
              className="flex align-items-center justify-content-space-between"
            >
              <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
                {labels.new_invitation}
              </p>
              <IconButton onClick={() => navigate("/invitations")}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      </Container>

      <div className="flex flex-dir-col align-items-center">
        <Paper
          className="w-85 flex justify-content-center align-items-center flex-dir-col mt-4 mb-2"
          sx={{ padding: "14px" }}
        >
          <FormControl className="w-100 flex justify-content-center align-items-center">
            <RadioGroup
              value={isProvider}
              onChange={handleProviderChange}
              row
              className="w-100 flex justify-content-space-evenly"
            >
              <FormControlLabel
                value={"visit"}
                control={<Radio />}
                label={labels.visit}
              />
              <FormControlLabel
                value={"provider"}
                control={<Radio />}
                label={labels.provider}
              />
            </RadioGroup>
          </FormControl>
        </Paper>
      </div>

      {isProvider === "visit" ? (
        <div>
          <div className="flex flex-dir-col align-items-center">
            <Paper
              className="w-85 flex justify-content-center align-items-center flex-dir-col mt-2 mb-2"
              sx={{ padding: "14px" }}
            >
              <div className="w-100 flex align-items-center justify-content-space-between m-0 p-0">
                <div className="flex align-items-center">
                  <p className="subtitle-color font-18 my-4">
                    {labels.multiple_access}
                  </p>
                  <IconButton
                    size="medium"
                    className="m-0 p-0 ml-4"
                    onClick={() => setTemporaryHelpModal(true)}
                  >
                    <HelpIcon
                      fontSize="inherit"
                      className="m-0 p-0 title-color"
                    />
                  </IconButton>
                </div>
                <TemporarySwitch className="ml-4" onChange={onChangeSwitch} />
              </div>
            </Paper>
          </div>

          {/* USER DATA FIELD ------------------------------------------------------------------------------------------------------------------------------>> */}
          {/* CAMPO DATOS DEL USUARIO ---------------------------------------------------------------------------------------------------------------------->> */}
          <div className="flex justify-content-center">
            <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
              <div className="flex align-items-center justify-content-space-between">
                <p className="subtitle-color font-18 mt-4 mb-6">
                  {labels.visitor_data}
                </p>
                {cmId !== ":cmId" || searchCommunityMember ? (
                  <div
                    onClick={() => {
                      setSearchCommunityMember(false);
                      setCommunityMemberVar(null);
                    }}
                    className="m-0 p-0 flex justify-content-center align-items-center button-style"
                  >
                    <CreateIcon
                      color="primary"
                      sx={{ fontSize: 35 }}
                      className="p-0 m-0"
                    />
                    <div className="flex flex-dir-col pr-3">
                      <p className="text-center p-0 m-0 font-12 text-primary-color">
                        {labels.new}
                      </p>
                      <p className="text-center p-0 m-0 font-12 text-primary-color">
                        {labels.register_button}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setSearchCommunityMember(true);
                      setAddName("");
                      setAddEmail("");
                      setAddPhone("");
                      setCountryCode("+52");
                    }}
                    className="m-0 p-0 flex justify-content-center align-items-center button-style"
                  >
                    <SearchRoundedIcon
                      color="primary"
                      sx={{ fontSize: 35 }}
                      className="p-0 m-0"
                    />
                    <div className="flex flex-dir-col pr-3">
                      <p className="text-center p-0 m-0 font-12 text-primary-color">
                        {labels.search_in}
                      </p>
                      <p className="text-center p-0 m-0 font-12 text-primary-color">
                        {labels.favorites}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {cmId !== ":cmId" ? (
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} className="my-3">
                    <TextField
                      value={addName}
                      label={communityMemberName}
                      fullWidth
                      disabled
                      onChange={(e) => {
                        setAddName(e.target.value);
                      }}
                    />
                  </Grid>
                  {filteredCommunityMember &&
                  filteredCommunityMember[0].phone.startsWith("+1") ? (
                    <Grid item xs={5}>
                      <FormControl fullWidth className="mt-3">
                        <Select
                          value={"2"}
                          disabled
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={1}>MX +52</MenuItem>
                          <MenuItem value={2}>US +1</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={5}>
                      <FormControl fullWidth className="mt-3">
                        <Select
                          value={"1"}
                          disabled
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={1}>MX +52</MenuItem>
                          <MenuItem value={2}>US +1</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {filteredCommunityMember &&
                  filteredCommunityMember[0].phone.startsWith("+1") ? (
                    <Grid item xs={7} className="my-3">
                      <TextField
                        value={addPhone}
                        label={communityMemberPhoneUS}
                        fullWidth
                        disabled
                        onChange={(e) => {
                          setAddPhone(e.target.value);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={7} className="my-3">
                      <TextField
                        value={addPhone}
                        label={communityMemberPhoneMX}
                        fullWidth
                        disabled
                        onChange={(e) => {
                          setAddPhone(e.target.value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} className="my-3">
                    <TextField
                      value={addEmail}
                      label={communityMemberEmail}
                      fullWidth
                      disabled
                      onChange={(e) => {
                        setAddEmail(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : searchCommunityMember ? (
                <>
                  <div className="m-0 p-0 mb-6 mt-3">
                    {/* <p className="subtitle-color m-0 p-0 mt-2 mb-5 ml-3">
                      {labels.search_in_favourites}
                    </p> */}
                    <Autocomplete
                      disablePortal
                      fullWidth
                      id="x"
                      options={communityMembersList}
                      value={communityMember}
                      onChange={(event: any, newValue: string | null) => {
                        setCommunityMemberVar(newValue);
                      }}
                      inputValue={inputAutocompleteCM}
                      onInputChange={(event, newInputValue) => {
                        setInputAutocompleteCM(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label={labels.add_favourite} />
                      )}
                    />
                  </div>
                  {communityMember !== null && (
                    <Grid container columnSpacing={1}>
                      <Grid item xs={12} className="my-3">
                        <TextField
                          value={addName}
                          label={filteredCommunityMemberName}
                          fullWidth
                          disabled
                          onChange={(e) => {
                            setAddName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl fullWidth className="mt-3">
                          <Select
                            value={filteredCommunityMemberPhoneCode}
                            disabled
                            inputProps={{ "aria-label": "Whithout label" }}
                          >
                            <MenuItem value={"+52"}>MX +52</MenuItem>
                            <MenuItem value={"+1"}>US +1</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={7} className="my-3">
                        <TextField
                          value={addPhone}
                          label={filteredCommunityMemberPhone}
                          disabled
                          fullWidth
                          onChange={(e) => {
                            setAddPhone(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className="my-3">
                        <TextField
                          value={addEmail}
                          label={filteredCommunityMemberEmail}
                          disabled
                          fullWidth
                          onChange={(e) => {
                            setAddEmail(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} className="my-3">
                    <TextField
                      required
                      value={addName}
                      label={labels.full_name}
                      fullWidth
                      onChange={(e) => {
                        setAddName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth className="mt-3">
                      <Select
                        value={countryCode}
                        onChange={(e) => {
                          setCountryCode(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"+52"}>MX +52</MenuItem>
                        <MenuItem value={"+1"}>US +1</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={7} className="my-3">
                    <TextField
                      required
                      value={addPhone}
                      label={labels.phone_number}
                      fullWidth
                      onChange={(e) => {
                        setAddPhone(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="my-3">
                    <TextField
                      value={addEmail}
                      label={labels.email}
                      fullWidth
                      onChange={(e) => {
                        setAddEmail(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {cmId === ":cmId" && !searchCommunityMember && (
                <>
                  <div className="flex align-items-center mt-3">
                    <Checkbox
                      checked={checked}
                      value={saveCommunityMember}
                      onChange={handleSaveCommunityMemberChange}
                    />
                    <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                      {labels.save_to_my_favorites}
                    </p>
                  </div>
                </>
              )}
            </Paper>
          </div>
        </div>
      ) : (
        <div className="flex flex-dir-col align-items-center">
          <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
            {/* <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.provider}
            </p> */}
            <Autocomplete
              disablePortal
              fullWidth
              options={providersList}
              onChange={(event: any, newValue: string | null) => {
                setProvider(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  value={provider}
                  onChange={(e) => {
                    setProvider(e.target.value);
                  }}
                  label={labels.provider}
                />
              )}
            />
            {provider === "Otro" && (
              <Grid item xs={12} className="mt-6 mb-3">
                <TextField
                  value={addProvider}
                  label={labels.specify_provider}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setAddProvider(e.target.value);
                  }}
                />
              </Grid>
            )}
          </Paper>
        </div>
      )}

      {/* OTHERS FIELD ------------------------------------------------------------------------------------------------------------------------------->> */}
      {/* CAMPO OTROS ------------------------------------------------------------------------------------------------------------------------------->> */}
      {invitationCustomAttributes && invitationCustomAttributes.length > 0 && (
        <div className="flex justify-content-center">
          <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
            <p className="subtitle-color font-18 mt-6 mb-6">{labels.others}</p>
            {invitationCustomAttributes.map(
              (
                {
                  attribute_name,
                  attribute_type,
                  required,
                  options,
                  value,
                }: any,
                index: number
              ) => (
                <div key={index} className="mt-5 mb-4">
                  <CustomAttributes
                    type={attribute_type}
                    name={attribute_name}
                    required={required}
                    value={value}
                    setValue={(newValue: any) => {
                      const newInvitationCustomAttributes = [
                        ...invitationCustomAttributes,
                      ];
                      newInvitationCustomAttributes[index].value = newValue;
                      setInvitationCustomAttributes(
                        newInvitationCustomAttributes
                      );
                    }}
                    listArray={options}
                  />
                </div>
              )
            )}
          </Paper>
        </div>
      )}

      {/* VALIDITY FIELD ------------------------------------------------------------------------------------------------------------------------------->> */}
      {/* CAMPO VIGENCIA ------------------------------------------------------------------------------------------------------------------------------->> */}
      {temporaryInvitation === true ? (
        <div className="flex justify-content-center">
          <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
            {/* <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.validity}
            </p> */}
            <p className="m-0 my-2 font-14 title-color">
              {labels.valid_from_date}
            </p>
            <Grid container>
              <Grid item xs={12} className="my-3">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // label={labels.date}
                    value={temporaryDate}
                    // onChange={(newDate: string | null) => {
                    //   setTemporaryDate(moment(newDate).format("DD/MMM/YYYY"));
                    // }}
                    onChange={(newDate: Date | null) => {
                      setTemporaryDate(newDate);
                    }}
                    inputFormat="DD/MMM/YYYY"
                    renderInput={(params: any) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <p className="m-0 my-2 font-14 title-color">{labels.to}</p>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Select
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                    <MenuItem value={"9"}>9</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"11"}>11</MenuItem>
                    <MenuItem value={"12"}>12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className="mb-4">
                  <Select
                    value={timeDuration}
                    onChange={(e) => {
                      setTimeDuration(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                  >
                    <MenuItem value={"days"}>{labels.days}</MenuItem>
                    <MenuItem value={"weeks"}>{labels.weeks}</MenuItem>
                    <MenuItem value={"months"}>{labels.months}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {showRestrictions ? (
              <>
                <p
                  className="text-primary-color flex align-items-center m-0 p-0 w-95 my-4 justify-content-flex-end"
                  onClick={() => setShowRestrictions(false)}
                >
                  <CloseRoundedIcon />
                  {labels.remove_restrictions}
                </p>
                <p className="w-100 title-color font-14 m-0 p-0 my-4">
                  {labels.select_access_days}
                </p>
                <div className="w-100">
                  <div className="w-100 flex justify-content-space-evenly align-items-center days-container">
                    <div
                      className={
                        mondayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickMonday()}
                    >
                      <p
                        className={
                          mondayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.mon}
                      </p>
                    </div>
                    <div
                      className={
                        tuesdayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickTuesday()}
                    >
                      <p
                        className={
                          tuesdayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.tue}
                      </p>
                    </div>
                    <div
                      className={
                        wednesdayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickWednesday()}
                    >
                      <p
                        className={
                          wednesdayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.wed}
                      </p>
                    </div>
                    <div
                      className={
                        thursdayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickThursday()}
                    >
                      <p
                        className={
                          thursdayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.thu}
                      </p>
                    </div>
                    <div
                      className={
                        fridayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickFriday()}
                    >
                      <p
                        className={
                          fridayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.fri}
                      </p>
                    </div>
                    <div
                      className={
                        saturdayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickSaturday()}
                    >
                      <p
                        className={
                          saturdayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.sat}
                      </p>
                    </div>
                    <div
                      className={
                        sundayCheck
                          ? "single-day-container-on"
                          : "single-day-container-off"
                      }
                      onClick={() => onClickSunday()}
                    >
                      <p
                        className={
                          sundayCheck ? "text-white" : "text-primary-color"
                        }
                      >
                        {labels.sun}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <p className="w-95 title-color font-12 m-0 p-0 my-4">
                  * {labels.not_selecting_day}
                </p> */}
                {/* <Divider className="w-100 my-5" /> */}
                <Grid container className="mt-5 mb-3">
                  <div className="w-100 flex justify-content-space-between">
                    <div className="w-70 mr-4">
                      <p className="m-0 my-2 font-14 title-color">
                        {labels.allow_entry_from}
                      </p>
                      <div className="flex align-items-center">
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <Select
                              value={hourTemporary}
                              onChange={(e) => {
                                setHourTemporary(e.target.value);
                              }}
                              inputProps={{ "aria-label": "Whithout label" }}
                            >
                              <MenuItem value={"00"}>00</MenuItem>
                              <MenuItem value={"01"}>01</MenuItem>
                              <MenuItem value={"02"}>02</MenuItem>
                              <MenuItem value={"03"}>03</MenuItem>
                              <MenuItem value={"04"}>04</MenuItem>
                              <MenuItem value={"05"}>05</MenuItem>
                              <MenuItem value={"06"}>06</MenuItem>
                              <MenuItem value={"07"}>07</MenuItem>
                              <MenuItem value={"08"}>08</MenuItem>
                              <MenuItem value={"09"}>09</MenuItem>
                              <MenuItem value={"10"}>10</MenuItem>
                              <MenuItem value={"11"}>11</MenuItem>
                              <MenuItem value={"12"}>12</MenuItem>
                              <MenuItem value={"13"}>13</MenuItem>
                              <MenuItem value={"14"}>14</MenuItem>
                              <MenuItem value={"15"}>15</MenuItem>
                              <MenuItem value={"16"}>16</MenuItem>
                              <MenuItem value={"17"}>17</MenuItem>
                              <MenuItem value={"18"}>18</MenuItem>
                              <MenuItem value={"19"}>19</MenuItem>
                              <MenuItem value={"20"}>20</MenuItem>
                              <MenuItem value={"21"}>21</MenuItem>
                              <MenuItem value={"22"}>22</MenuItem>
                              <MenuItem value={"23"}>23</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <span className="mx-3"> : </span>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <Select
                              value={
                                hourTemporary === "--" ? "--" : minutesTemporary
                              }
                              onChange={(e) => {
                                setMinutesTemporary(e.target.value);
                              }}
                              inputProps={{ "aria-label": "Whithout label" }}
                            >
                              {/* <MenuItem value={"--"} disabled>
                                --
                              </MenuItem> */}
                              <MenuItem
                                value={"00"}
                                disabled={hourTemporary === "--"}
                              >
                                00
                              </MenuItem>
                              <MenuItem
                                value={"15"}
                                disabled={hourTemporary === "--"}
                              >
                                15
                              </MenuItem>
                              <MenuItem
                                value={"30"}
                                disabled={hourTemporary === "--"}
                              >
                                30
                              </MenuItem>
                              <MenuItem
                                value={"45"}
                                disabled={hourTemporary === "--"}
                              >
                                45
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </div>
                      {/* <p className="m-0 my-2 font-12 title-color">
                        {labels.select_entry_time}
                      </p> */}
                    </div>
                    <div className="w-30 ml-4">
                      <p className="m-0 my-2 font-14 title-color">
                        {labels.until}
                      </p>
                      <div>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Select
                              value={
                                hourTemporary === "--"
                                  ? "--"
                                  : hoursLaterTemporary
                              }
                              onChange={(e) => {
                                setHoursLaterTemporary(e.target.value);
                              }}
                              inputProps={{ "aria-label": "Whithout label" }}
                            >
                              <MenuItem
                                value={"1"}
                                disabled={hourTemporary === "--"}
                              >
                                1
                              </MenuItem>
                              <MenuItem
                                value={"2"}
                                disabled={hourTemporary === "--"}
                              >
                                2
                              </MenuItem>
                              <MenuItem
                                value={"3"}
                                disabled={hourTemporary === "--"}
                              >
                                3
                              </MenuItem>
                              <MenuItem
                                value={"4"}
                                disabled={hourTemporary === "--"}
                              >
                                4
                              </MenuItem>
                              <MenuItem
                                value={"5"}
                                disabled={hourTemporary === "--"}
                              >
                                5
                              </MenuItem>
                              <MenuItem
                                value={"6"}
                                disabled={hourTemporary === "--"}
                              >
                                6
                              </MenuItem>
                              <MenuItem
                                value={"7"}
                                disabled={hourTemporary === "--"}
                              >
                                7
                              </MenuItem>
                              <MenuItem
                                value={"8"}
                                disabled={hourTemporary === "--"}
                              >
                                8
                              </MenuItem>
                              <MenuItem
                                value={"9"}
                                disabled={hourTemporary === "--"}
                              >
                                9
                              </MenuItem>
                              <MenuItem
                                value={"10"}
                                disabled={hourTemporary === "--"}
                              >
                                10
                              </MenuItem>
                              <MenuItem
                                value={"11"}
                                disabled={hourTemporary === "--"}
                              >
                                11
                              </MenuItem>
                              <MenuItem
                                value={"12"}
                                disabled={hourTemporary === "--"}
                              >
                                12
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </div>
                      <p className="m-0 my-2 font-12 title-color">
                        {labels.hours_later}
                      </p>
                    </div>
                  </div>
                </Grid>
              </>
            ) : (
              <p
                className="text-primary-color flex align-items-center m-0 p-0 w-95 my-4 justify-content-flex-end"
                onClick={() => setShowRestrictions(true)}
              >
                <ArrowDropDownIcon />
                {labels.restrict_days_hours}
              </p>
            )}
          </Paper>
        </div>
      ) : (
        <div className="flex justify-content-center">
          <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
            <p className="m-0 my-2 font-14 title-color">
              {labels.valid_for_the_day}
            </p>
            <Grid container>
              <Grid item xs={12} className="my-3">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={date}
                    onChange={(newDate: Date | null) => {
                      setDate(newDate);
                    }}
                    inputFormat="DD/MMM/YYYY"
                    renderInput={(params: any) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container>
              <div className="w-100 flex justify-content-space-between">
                <div className="w-70 mr-4">
                  <p className="m-0 my-2 font-14 title-color">
                    {labels.allow_entry_from}
                  </p>
                  <div className="flex align-items-center">
                    <Grid item xs={5}>
                      <FormControl fullWidth>
                        <Select
                          value={hour}
                          onChange={(e) => {
                            setHour(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={"00"}>00</MenuItem>
                          <MenuItem value={"01"}>01</MenuItem>
                          <MenuItem value={"02"}>02</MenuItem>
                          <MenuItem value={"03"}>03</MenuItem>
                          <MenuItem value={"04"}>04</MenuItem>
                          <MenuItem value={"05"}>05</MenuItem>
                          <MenuItem value={"06"}>06</MenuItem>
                          <MenuItem value={"07"}>07</MenuItem>
                          <MenuItem value={"08"}>08</MenuItem>
                          <MenuItem value={"09"}>09</MenuItem>
                          <MenuItem value={"10"}>10</MenuItem>
                          <MenuItem value={"11"}>11</MenuItem>
                          <MenuItem value={"12"}>12</MenuItem>
                          <MenuItem value={"13"}>13</MenuItem>
                          <MenuItem value={"14"}>14</MenuItem>
                          <MenuItem value={"15"}>15</MenuItem>
                          <MenuItem value={"16"}>16</MenuItem>
                          <MenuItem value={"17"}>17</MenuItem>
                          <MenuItem value={"18"}>18</MenuItem>
                          <MenuItem value={"19"}>19</MenuItem>
                          <MenuItem value={"20"}>20</MenuItem>
                          <MenuItem value={"21"}>21</MenuItem>
                          <MenuItem value={"22"}>22</MenuItem>
                          <MenuItem value={"23"}>23</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <span className="mx-3"> : </span>
                    <Grid item xs={5}>
                      <FormControl fullWidth>
                        <Select
                          value={minutes}
                          onChange={(e) => {
                            setMinutes(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={"00"}>00</MenuItem>
                          <MenuItem value={"15"}>15</MenuItem>
                          <MenuItem value={"30"}>30</MenuItem>
                          <MenuItem value={"45"}>45</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </div>
                </div>
                <div className="w-30 ml-4">
                  <p className="m-0 my-2 font-14 title-color">{labels.until}</p>
                  <div>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Select
                          value={hoursLater}
                          onChange={(e) => {
                            setHoursLater(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"2"}>2</MenuItem>
                          <MenuItem value={"3"}>3</MenuItem>
                          <MenuItem value={"4"}>4</MenuItem>
                          <MenuItem value={"5"}>5</MenuItem>
                          <MenuItem value={"6"}>6</MenuItem>
                          <MenuItem value={"7"}>7</MenuItem>
                          <MenuItem value={"8"}>8</MenuItem>
                          <MenuItem value={"9"}>9</MenuItem>
                          <MenuItem value={"10"}>10</MenuItem>
                          <MenuItem value={"11"}>11</MenuItem>
                          <MenuItem value={"12"}>12</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </div>
                  <p className="m-0 my-2 font-12 title-color">
                    {labels.hours_later}
                  </p>
                </div>
              </div>
            </Grid>
          </Paper>
        </div>
      )}

      {/* VISIT DETAILS FIELD -------------------------------------------------------------------------------------------------------------------------->> */}
      {/* CAMPO DETALLES DE LA VISITA ------------------------------------------------------------------------------------------------------------------>> */}
      <div className="flex justify-content-center">
        <Paper className="w-85 my-2" sx={{ padding: "14px" }}>
          <p className="subtitle-color font-18 mt-6 mb-6">
            {temporaryInvitation === true
              ? labels.allowed_access_points
              : labels.allowed_access_point}{" "}
            *
          </p>
          {temporaryInvitation === false ? (
            <>
              {user?.access_servers.map(({ name, venue_name, id }, index) => (
                <div key={id} className="flex align-items-center mb-2">
                  <Checkbox
                    onChange={() => {
                      let elementFind = finalAccessPointTemporary.find(
                        (element) => element === id
                      );
                      let finalIndex = finalAccessPointTemporary.indexOf(id);
                      const finalAccessPointTemporaryClone = [
                        ...finalAccessPointTemporary,
                      ];
                      if (!elementFind) {
                        finalAccessPointTemporaryClone.push(id);
                      } else {
                        finalAccessPointTemporaryClone.splice(finalIndex, 1);
                      }
                      setFinalAccessPointTemporary(
                        finalAccessPointTemporaryClone
                      );
                    }}
                    color="secondary"
                  />
                  <div className="flex flex-dir-col">
                    <p className="subtitle-color font-14 m-0 p-0">{name}</p>
                    <p className="title-color font-12 m-0 p-0">{venue_name}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {user?.access_servers.map(({ name, venue_name, id }, index) => (
                <div key={id} className="flex align-items-center mb-2">
                  <Checkbox
                    onChange={() => {
                      let elementFind = finalAccessPointTemporary.find(
                        (element) => element === id
                      );
                      let finalIndex = finalAccessPointTemporary.indexOf(id);
                      const finalAccessPointTemporaryClone = [
                        ...finalAccessPointTemporary,
                      ];
                      if (!elementFind) {
                        finalAccessPointTemporaryClone.push(id);
                      } else {
                        finalAccessPointTemporaryClone.splice(finalIndex, 1);
                      }
                      setFinalAccessPointTemporary(
                        finalAccessPointTemporaryClone
                      );
                    }}
                    color="secondary"
                  />
                  <div className="flex flex-dir-col">
                    <p className="subtitle-color font-14 m-0 p-0">{name}</p>
                    <p className="title-color font-12 m-0 p-0">{venue_name}</p>
                  </div>
                </div>
              ))}
            </>
          )}
          <Divider className="w-100 mt-3 mb-5" />
          <Grid
            item
            xs={12}
            className={temporaryInvitation ? "my-4" : "mt-4 mb-2"}
          >
            <TextField
              required
              value={reasonForVisit}
              label={labels.visit_reason}
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              onChange={(e) => {
                setReasonForVisit(e.target.value);
              }}
            />
          </Grid>
          {temporaryInvitation === true && (
            <>
              <div className="flex">
                <Checkbox
                  checked={checkedAntiPassback}
                  value={antiPassback}
                  onChange={handleAntiPassbackChange}
                  disabled={disabledAntipassback}
                />
                <p
                  className="subtitle-color-ni font-14 medium"
                  style={{ color: disabledAntipassback === true ? "#bdbdbd" : "" }}
                >
                  {labels.anti_passback_text}
                </p>
              </div>
            </>
          )}
        </Paper>
      </div>

      <div className="flex flex-dir-col w-100 align-items-center justify-content-center mt-6 mb-10">
        <p className="subtitle-color p-0 m-0 mb-5 text-center">
          * {labels.required_fields}
        </p>
        <Button
          variant="contained"
          size="large"
          className="py-6 w-80"
          sx={{ fontWeight: "normal" }}
          disableElevation
          disabled={
            isLoading
              ? isLoading
              : invitationCustomAttributes &&
                invitationCustomAttributes.length > 0
              ? temporaryInvitation
                ? cmId === ":cmId"
                  ? communityMember !== null
                    ? isDisabledTemporaryCmIdCA
                    : isDisabledTemporaryCA
                  : isDisabledTemporaryCmIdCA
                : isProvider === "visit"
                ? cmId === ":cmId"
                  ? communityMember !== null
                    ? isDisabledVisitorCmIdCA
                    : isDisabledVisitorCA
                  : isDisabledVisitorCmIdCA
                : provider === "Otro"
                ? isDisabledProviderOtherCA
                : isDisabledProviderCA
              : temporaryInvitation
              ? cmId === ":cmId"
                ? communityMember !== null
                  ? isDisabledTemporaryCmId
                  : isDisabledTemporary
                : isDisabledTemporaryCmId
              : isProvider === "visit"
              ? cmId === ":cmId"
                ? communityMember !== null
                  ? isDisabledVisitorCmId
                  : isDisabledVisitor
                : isDisabledVisitorCmId
              : provider === "Otro"
              ? isDisabledProviderOther
              : isDisabledProvider
          }
          onClick={() => {
            generateAccessKey();
          }}
        >
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : isProvider === "visit" ? (
            labels.generate_invitation
          ) : (
            labels.generate_inv
          )}
        </Button>
      </div>
      <div className="lower-div root-background" />

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {labels.invitation_generated}
            </p>
            {isProvider === "visit" && (
              <p className="title-color font-16 my-7 text-center">
                {labels.invitation_text}
              </p>
            )}
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => navigate("/invitations"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal disableEscapeKeyDown open={temporaryHelpModal}>
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="flex justify-content-space-between align-items-center m-0 p-0"
            >
              <p className="subtitle-color font-18 m-0 p-0">
                {labels.multiple_access}
              </p>
              <IconButton onClick={() => setTemporaryHelpModal(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className="w-100" />
          <p>{labels.temporary_help_modal_text}</p>
        </Box>
      </Modal>
    </>
  );
};

export default NewInvitation;
