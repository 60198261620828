// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-webcam-container {
  width: 94.1176470588%;
  height: 70%;
  background: black;
  border-radius: 6px;
}

.webcam-style {
  border: lightgrey 8px solid;
  border-radius: 6px;
}

.image-style {
  width: 94.1176470588%;
  border: lightgrey 8px solid;
  border-radius: 6px;
}

.icon-button-style {
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.back1 {
  background-color: aqua;
}`, "",{"version":3,"sources":["webpack://./src/components/CaptureRegisterImage/capture-register-image.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,2BAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,2BAAA;EACA,kBAAA;AACF;;AAEA;EACI,8BAAA;EACA,4BAAA;EACA,+BAAA;AACJ;;AAEA;EACE,sBAAA;AACF","sourcesContent":[".div-webcam-container {\n  width: 94.11764705882353%;\n  height: calc(100% * 0.7);\n  background: black;\n  border-radius: 6px;\n}\n\n.webcam-style {\n  border: lightgrey 8px solid;\n  border-radius: 6px;\n}\n\n.image-style {\n  width: 94.11764705882353%;\n  border: lightgrey 8px solid;\n  border-radius: 6px;\n}\n\n.icon-button-style {\n    border-radius: 10px !important;\n    padding-top: 10px !important;\n    padding-bottom: 10px !important;\n}\n\n.back1 {\n  background-color: aqua;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
