// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100vw;
  height: 116px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--main-primary-color);
  padding-top: 60px;
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  z-index: 97;
}

.main-container-desktop {
  width: 100%;
  height: 116px;
  background-color: #d9d9d9;
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  z-index: 97;
}

.title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-text {
  color: white;
  font-size: 24px;
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/MainPagesHeader/main-pages-header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,2CAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,mBAAA;AACF","sourcesContent":[".main-container {\n  width: 100vw;\n  height: 116px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: var(--main-primary-color);\n  padding-top: 60px;\n  display: \"flex\";\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: space-between;\n  z-index: 97;\n}\n\n.main-container-desktop {\n  width: 100%;\n  height: 116px;\n  background-color: #d9d9d9;\n  display: \"flex\";\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: space-between;\n  z-index: 97;\n}\n\n.title-container {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title-text {\n  color: white;\n  font-size: 24px;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
