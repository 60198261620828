// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-size {
  max-width: "100%";
}

.company-logo {
  height: 45px;
}

.passtrack-logo {
  height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/ContentToPrint/content-to-print.scss"],"names":[],"mappings":"AAAA;EACA,iBAAA;AACA;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".photo-size {\nmax-width: '100%';\n}\n\n.company-logo {\n  height: 45px;\n}\n\n.passtrack-logo {\n  height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
