// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-container {
  margin-top: 60px;
}

.side-menu-container {
  width: 250px;
}

.content-container {
  margin-left: 250px;
  width: calc(100% - 250px);
}

.account-menu {
  width: 100px;
}

.account-options {
  bottom: -60px;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuContainer/menu-container.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;AACF","sourcesContent":[".mobile-container {\n  margin-top: 60px;\n}\n\n.side-menu-container {\n  width: 250px;\n}\n\n.content-container {\n  margin-left: 250px;\n  width: calc(100% - 250px);\n}\n\n.account-menu {\n  width: 100px;\n}\n\n.account-options {\n  bottom: -60px;\n  right: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
