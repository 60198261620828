// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lower-menu-style {
  height: auto;
}

.lower-menu-button-style {
  border: none !important;
  border-radius: 0px !important;
}

.upper-line-button {
  position: absolute;
  top: 0;
  height: 4px;
  background: var(--main-primary-color);
}

.general-upper-line {
  position: absolute;
  top: 0;
  height: 2px;
  background-color: var(--main-primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MobileLowerMenu/mobile-lower-menu.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,uBAAA;EAEA,6BAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,qCAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,sDAAA;AAAJ","sourcesContent":[".lower-menu-style {\n    height: auto;\n}\n\n.lower-menu-button-style {\n    border: none !important;\n    // width: 25vw;\n    border-radius: 0px !important;\n}\n\n.upper-line-button {\n    position: absolute;\n    top: 0;\n    height: 4px;\n    background: var(--main-primary-color);\n}\n\n.general-upper-line {\n    position: absolute;\n    top: 0;\n    height: 2px;\n    background-color: var(--main-primary-color) !important;\n}\n\n.pressed-menu-button {\n    // background: #dfdfdf !important;\n    // background: var(--main-white-color) !important;\n    // background: var(--main-primary-color) !important;\n    // color: white !important;\n    // background: #eee !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
