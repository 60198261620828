import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import AdminDesktopHeader from "../AdminDesktopHeader";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import api, { Users } from "../../api";
import { FaEdit, FaFileDownload } from "react-icons/fa";
import AdminDesktopNewUser from "../AdminDesktopNewUser";
import SearchInputOutlined from "../SearchInputOutlined";
import { BsFillPlusCircleFill } from "react-icons/bs";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment/locale/es";
import { selectStrapiUnit } from "../../slices/strapiUnit";
import { StrapiUser } from "../../strapiModels/strapiUser";
import { selectUserRole } from "../../slices/user";
import { selectStrapiUserRole } from "../../slices/strapiUser";
import { showError } from "../../utils/helper";
import { selectStrapiProperty } from "../../slices/strapiProperty";
interface FileToExport {
  nombre: string;
  departamento: string;
  "correo electronico": string;
  telefono: string;
  rol: string;
  "creado por": string;
}

interface AdminDesktopUserContentProps {
  userType: "residents" | "admin" | "guard";
}

const AdminDesktopUsersContent = ({
  userType,
}: AdminDesktopUserContentProps) => {
  const labels = useAppSelector(selectLabels);
  const unit = useAppSelector(selectStrapiUnit);
  const userRole = useAppSelector(selectStrapiUserRole);
  const dispatch = useAppDispatch();
  const property = useAppSelector(selectStrapiProperty);

  console.log("userRole", userRole);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [usersList, setUsersList] = React.useState<StrapiUser[] | null>(null);
  const [filteredUsers, setFilteredUsers] = React.useState<Users[] | null>(
    null
  );
  const [filteredUsersBackup, setFilteredUsersBackup] = React.useState<
    Users[] | null
  >(null);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [newUserWindow, setNewUserWindow] = React.useState<boolean>(false);
  const [pages, setPages] = React.useState<number | null>(null);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [editUserWindow, setEditUserWindow] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<Users | null>(null);

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentItems = filteredUsers
    ? filteredUsers.slice(startIndex, endIndex)
    : "";

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const currentDate = new Date();
  const fileName = `${
    userType === "residents"
      ? labels.authorized_users
      : userType === "admin"
      ? labels.authorized_admins
      : labels.authorized_guards
  } - ${moment(currentDate).format("LL")}`;

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useLayoutEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  // React.useEffect(() => {
  //   getFilteredUsers(userType);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [usersList, userType]);

  // const getFilteredUsers = (userType: "residents" | "admin" | "guard") => {
  //   if (usersList && usersList.length > 0) {
  //     const guardResponse = usersList.filter(
  //       ({ roles }) => roles[0]?.name === "security_guard"
  //     );
  //     const adminResponse = usersList.filter(
  //       ({ roles }) => roles[0]?.name === "network_admin"
  //     );
  //     const hostResponse = usersList.filter(
  //       ({ roles }) =>
  //         roles[0]?.name === "host" || roles[0]?.name === "secondary_user"
  //     );
  //     if (userType === "admin") {
  //       setFilteredUsers(adminResponse);
  //       setFilteredUsersBackup(adminResponse);
  //       setFileArray(
  //         adminResponse.map(
  //           ({ id, network_group, name, email, phone, roles, invited_by }) => ({
  //             nombre: `${name}`,
  //             departamento: `${network_group}`,
  //             "correo electronico": `${email ? email : labels.no_email}`,
  //             telefono: `${phone ? phone : labels.no_phone}`,
  //             rol: `${labels.network_admin}`,
  //             "creado por": `${invited_by?.name}`,
  //           })
  //         )
  //       );
  //     }
  //     if (userType === "guard") {
  //       setFilteredUsers(guardResponse);
  //       setFilteredUsersBackup(guardResponse);
  //       setFileArray(
  //         guardResponse.map(
  //           ({ id, network_group, name, email, phone, roles, invited_by }) => ({
  //             nombre: `${name}`,
  //             departamento: `${network_group}`,
  //             "correo electronico": `${email ? email : labels.no_email}`,
  //             telefono: `${phone ? phone : labels.no_phone}`,
  //             rol: labels.security_guard,
  //             "creado por": `${invited_by?.name}`,
  //           })
  //         )
  //       );
  //     }
  //     if (userType === "residents") {
  //       setFilteredUsers(hostResponse);
  //       setFilteredUsersBackup(hostResponse);
  //       setFileArray(
  //         hostResponse.map(
  //           ({ id, network_group, name, email, phone, roles, invited_by }) => ({
  //             nombre: `${name}`,
  //             departamento: `${network_group}`,
  //             "correo electronico": `${email ? email : labels.no_email}`,
  //             telefono: `${phone ? phone : labels.no_phone}`,
  //             rol:
  //               roles[0]?.name === "host" ? labels.host : labels.secondary_host,
  //             "creado por": `${invited_by?.name}`,
  //           })
  //         )
  //       );
  //     }
  //   }
  // };

  React.useEffect(() => {
    handleInputChange();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const handleInputChange = () => {
    if (filteredUsers && filteredUsersBackup) {
      if (debouncedSearchText) {
        const filteredResults = filteredUsersBackup.filter(({ name }) =>
          name.toLowerCase().includes(debouncedSearchText.toLowerCase())
        );
        setFilteredUsers(filteredResults);
      } else {
        setFilteredUsers(filteredUsersBackup);
      }
    }
  };

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  React.useEffect(() => {
    if (filteredUsers) {
      setPages(Math.ceil(filteredUsers?.length / rowsPerPage));
    }
  }, [filteredUsers, rowsPerPage]);

  React.useEffect(() => {
    getAuthorizedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  // const getAuthorizedUsers = async () => {
  //   const pageSize = 1000;
  //   let totalPages = 1;
  //   let allResults: Users[] = [];
  //   try {
  //     setIsLoading(true);
  //     const response = await api.usersByNetworkRequest(1, pageSize, "");
  //     allResults.push(...response.users);
  //     totalPages = response.total_pages;
  //     if (totalPages > 1) {
  //       for (let page = 2; page <= totalPages; page++) {
  //         const nextPageResponse = await api.usersByNetworkRequest(
  //           page,
  //           pageSize,
  //           ""
  //         );
  //         allResults.push(...nextPageResponse.users);
  //       }
  //     }
  //     setUsersList(allResults);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getAuthorizedUsers = async () => {
    const hostRoleId = 3;
    const adminRoleId = 6;
    const secHostRoleId = 5;
    const guardRoleId = 4;
    let usersResponse: StrapiUser[] | null = null;
    if (unit && property) {
      try {
        setIsLoading(true);
        if (userType === "residents") {
          if (userRole === "host") {
            const response = await api.strapiUsersByUnitRequest(
              unit?.id,
              hostRoleId,
              secHostRoleId
            );
            if (response) {
              usersResponse = response;
            }
          } else {
            const response = await api.strapiUsersRequest(
              property.id,
              hostRoleId,
              secHostRoleId
            );
            if (response) {
              usersResponse = response;
            }
          }
        }
        if (userType === "admin") {
          const response = await api.strapiUsersRequest(property.id, adminRoleId);
          if (response) {
            usersResponse = response;
          }
        }
        if (userType === "guard") {
          const response = await api.strapiUsersRequest(property.id, guardRoleId);
          if (response) {
            usersResponse = response;
          }
        }
        if (usersResponse) {
          setUsersList(usersResponse);
        }
      } catch (error) {
        console.log(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  console.log("usersList", usersList);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <AdminDesktopDropView
          showView={newUserWindow}
          setShowView={setNewUserWindow}
          content={
            <AdminDesktopNewUser
              userType={
                userType === "residents"
                  ? "residents"
                  : userType === "admin"
                  ? "admin"
                  : "guard"
              }
              functionType={"new"}
              headerRef={headerRef}
              buttonAction={() => setNewUserWindow(false)}
              open={newUserWindow}
              setOpen={setNewUserWindow}
              onCloseFunction={getAuthorizedUsers}
            />
          }
          title={
            userType === "residents"
              ? labels.new_host
              : userType === "admin"
              ? labels.new_admin
              : labels.new_guard
          }
          extraFunc={false}
        />
        <AdminDesktopDropView
          showView={editUserWindow}
          setShowView={setEditUserWindow}
          content={
            <AdminDesktopNewUser
              userType={
                userType === "residents"
                  ? "residents"
                  : userType === "admin"
                  ? "admin"
                  : "guard"
              }
              functionType={"edit"}
              headerRef={headerRef}
              buttonAction={() => setNewUserWindow(false)}
              userById={userId}
              open={editUserWindow}
              setOpen={setEditUserWindow}
              onCloseFunction={getAuthorizedUsers}
            />
          }
          title={labels.edit_user}
          extraFunc={false}
        />
        <AdminDesktopHeader
          title={
            userType === "residents"
              ? userRole === "host"
                ? `${labels.users.users} ${unit?.attributes?.name}`
                : labels.authorized_users
              : userType === "admin"
              ? labels.authorized_admins
              : labels.authorized_guards
          }
          buttonLabel={""}
          refObject={headerRef}
          buttonAction={() => setNewUserWindow(true)}
        />
        <div
          ref={filtersRef}
          style={{
            width: "100%",
            boxSizing: "border-box",
            minHeight: 60,
            paddingLeft: 20,
            paddingRight: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ width: "35%" }}>
            <SearchInputOutlined
              value={searchInput}
              setValue={setSearchInput}
              size="small"
            />
          </div>
          <div style={{ marginRight: 6, marginLeft: 20 }}>
            {fileArray && fileArray?.length > 0 ? (
              <CSVLink data={fileArray} filename={fileName}>
                <FaFileDownload
                  fontSize={32}
                  color="#10418a"
                  style={{ cursor: "pointer" }}
                />
              </CSVLink>
            ) : (
              <FaFileDownload fontSize={32} color="#999999" />
            )}
          </div>
          <div style={{ marginRight: 6, marginLeft: 20 }}>
            <BsFillPlusCircleFill
              fontSize={32}
              color="#10418a"
              onClick={() => setNewUserWindow(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${mainContentHeight}px - 25px)`,
            backgroundColor: "white",
            boxSizing: "border-box",
            marginLeft: 20,
            marginRight: 20,
            borderRadius: 4,
            position: "relative",
            boxShadow:
              "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            overflow: "auto",
          }}
        >
          <div ref={tableHeader} style={{ width: "100%" }}>
            <table style={{ width: "100%" }}>
              <thead
                style={{
                  height: "auto",
                  backgroundColor: "#e8e8e8",
                }}
              >
                <tr>
                  {userRole !== "host" && (
                    <th
                      style={{
                        paddingTop: 16,
                        paddingBottom: 16,
                        fontWeight: 500,
                        // width: "15%",
                        width: "20%",
                      }}
                    >
                      {labels.unit_admin}
                    </th>
                  )}
                  <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "15%",
                    }}
                  >
                    {labels.name}
                  </th>
                  <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "22.5%",
                    }}
                  >
                    {labels.email}
                  </th>
                  <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "12.5%",
                    }}
                  >
                    {labels.phone}
                  </th>
                  <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "10%",
                    }}
                  >
                    {labels.role}
                  </th>
                  <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "20%",
                    }}
                  >
                    {labels.created_by}
                  </th>
                  {/* <th
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      width: "5%",
                    }}
                  >
                    {""}
                  </th> */}
                </tr>
              </thead>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              height: `calc(100% - ${containersHeight}px)`,
              overflowY: "auto",
              backgroundColor: "#f8f8f8",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <table style={{ width: "100%" }}>
                <tbody
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  {usersList && usersList.length > 0 ? (
                    usersList.map((element) => (
                      <tr key={element?.id}>
                        {userRole !== "host" && (
                          <td
                            className="desktop-tables-font"
                            style={{
                              boxSizing: "border-box",
                              padding: 10,
                              // width: "15%",
                              width: "20%",
                            }}
                          >
                            {element?.unit?.name
                              ? element?.unit?.name
                              : labels.management}
                          </td>
                        )}
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "15%",
                          }}
                        >
                          {element?.username ? element?.username : ""}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "22.5%",
                          }}
                        >
                          {element?.email ? (
                            <p>{element?.email}</p>
                          ) : (
                            <p style={{ color: "#999999" }}>
                              {labels.no_email}
                            </p>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "12.5%",
                            textAlign: "center",
                          }}
                        >
                          {element?.phone ? (
                            <p>{element?.phone}</p>
                          ) : (
                            <p style={{ color: "#999999" }}>
                              {labels.no_phone}
                            </p>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {element?.role?.name === "host" && labels.host}
                          {element?.role?.name === "secondary_user" &&
                            labels.secondary_host}
                          {element?.role?.name === "security_guard" &&
                            labels.security_guard}
                          {element?.role?.name === "network_admin" &&
                            labels.network_admin}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "20%",
                            textAlign: "center",
                          }}
                        >
                          {/* {element?.invited_by?.email ? (
                            <p>{element?.invited_by?.email}</p>
                          ) : (
                            <p style={{ color: "#999999" }}>{labels.no_user}</p>
                          )} */}
                          -
                        </td>
                        {/* <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "5%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <FaEdit
                            fontSize={20}
                            color="#10418a"
                            onClick={() => {
                              setUserId(element);
                              setEditUserWindow(true);
                            }}
                          />
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <p
                      className="font-16 subtitle-color"
                      style={{ textAlign: "center" }}
                    >
                      {labels.no_results_found}
                    </p>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div
            ref={tableFooter}
            style={{
              width: "100%",
              height: "50px",
              backgroundColor: "#e8e8e8",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="flex align-items-center ml-8 mr-4">
              <p className="pr-4 primary-color">{labels.rows}</p>
              <FormControl
                style={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: 5,
                  width: 100,
                }}
              >
                <Select
                  value={rowsPerPage}
                  style={{ color: "#10418a" }}
                  size="small"
                  onChange={(e) => {
                    setRowsPerPage(Number(e.target.value));
                    setPage(1);
                  }}
                  inputProps={{ "aria-label": "Whithout label" }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </div>
            {filteredUsers && (
              <div className="flex" style={{ alignItems: "center" }}>
                <div className="flex mx-2">
                  <p className="primary-color">
                    {labels.page} <span className="primary-color">{page}</span>{" "}
                    {labels.of} <span className="primary-color">{pages}</span>
                  </p>
                </div>
                <div className="mx-2">
                  <IconButton
                    color="primary"
                    disabled={page <= 1}
                    onClick={handleChangeDownPage}
                  >
                    <NavigateBeforeRoundedIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={pages ? page >= pages : false}
                    onClick={handleChangeUpPage}
                  >
                    <NavigateNextRoundedIcon />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDesktopUsersContent;
