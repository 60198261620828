// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-list-container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.main-list-container-deleted {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(161, 161, 161, 0.15);
  position: relative;
}

.deleted-text {
  color: #a60300;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  opacity: 75%;
}

.deleted-text-container {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/InvitationList/invitation-list.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,2CAAA;AACF;;AAEA;EAEE,uBAAA;EACA,kBAAA;EACA,iDAAA;EACA,kBAAA;AAAF;;AAGA;EAEE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AADF;;AAIA;EACE,kBAAA;EACA,qBAAA;EACA,SAAA;AADF","sourcesContent":[".main-list-container {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);\n}\n\n.main-list-container-deleted {\n  // background-color: #b8b8b8;\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0px 2px 4px rgba(161, 161, 161, 0.15);\n  position: relative;\n}\n\n.deleted-text {\n  // color: rgba(255, 255, 255, 0.7);\n  color: #a60300;\n  font-size: 20px;\n  font-weight: bold;\n  text-transform: uppercase;\n  margin: 0;\n  padding: 0;\n  opacity: 75%;\n}\n\n.deleted-text-container {\n  position: absolute;\n  top: calc(50% - 10px);\n  left: calc(50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
