import React from "react";
import { useAppDispatch } from "../../app/hooks";
import AdminDesktopCommunityMembers from "../../components/AdminDesktopCommunityMembers";
import AdminDesktopDepartments from "../../components/AdminDesktopDepartments";
import AdminDesktopEventsContent from "../../components/AdminDesktopEventsContent";
import AdminDesktopHeader from "../../components/AdminDesktopHeader";
import AdminDesktopIntelligenceContent from "../../components/AdminDesktopIntelligenceContent";
import AdminDesktopInvitationContent from "../../components/AdminDesktopInvitationContent";
import AdminDesktopLogbookContent from "../../components/AdminDesktopLogbookContent";
import AdminDesktopUsersContent from "../../components/AdminDesktopUsersContent";
import AdminLeftMenu from "../../components/AdminLeftMenu";
import { resetToken } from "../../slices/token";
import { resetUser } from "../../slices/user";
import AdminDesktopAccessPoints from "../../components/AdminDesktopAccessPoints/AdminDesktopAccessPoints";
import { useNavigate } from "react-router-dom";
import { resetStrapiUser } from "../../slices/strapiUser";
import { resetStrapiProperty } from "../../slices/strapiProperty";
import { resetStrapiUnit } from "../../slices/strapiUnit";
import AdminDesktopOrganizationalArea from "../../components/AdminDesktopOrganizationalArea";

const DesktopAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = React.useState<string>("invitations");

  React.useEffect(() => {
    if (selectedPage === "logout") {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  const onLogout = () => {
    dispatch(resetToken());
    dispatch(resetStrapiUser());
    dispatch(resetStrapiProperty())
    dispatch(resetStrapiUnit())
    navigate("/");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        display: "flex",
      }}
    >
      <div
        style={{
          width: "20%",
          height: "100vh",
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          backgroundColor: "white",
          borderRightColor: "#e8e8e8",
          borderRightStyle: "solid",
          borderRightWidth: 1,
        }}
      >
        <AdminLeftMenu
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      <div style={{ width: "80%", boxSizing: "border-box" }}>
        {selectedPage === "invitations" && <AdminDesktopInvitationContent />}
        {selectedPage === "logbook" && <AdminDesktopLogbookContent />}
        {selectedPage === "users" && (
          <AdminDesktopUsersContent userType="residents" />
        )}
        {selectedPage === "admin" && (
          <AdminDesktopUsersContent userType="admin" />
        )}
        {selectedPage === "guard" && (
          <AdminDesktopUsersContent userType="guard" />
        )}
        {selectedPage === "intelligence" && <AdminDesktopIntelligenceContent />}
        {selectedPage === "departments" && <AdminDesktopDepartments />}
        {selectedPage === "events" && <AdminDesktopEventsContent />}
        {selectedPage === "access_points" && <AdminDesktopAccessPoints />}
        {selectedPage === "registered" && <AdminDesktopCommunityMembers />}
        {selectedPage === "organizational_area" && <AdminDesktopOrganizationalArea />}
      </div>
    </div>
  );
};

export default DesktopAdmin;
