// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-code-background {
  border-radius: 4px;
  margin-top: 18px;
  width: auto;
}

.qr-container {
  display: flex;
  background-color: white;
  padding: 14px;
  justify-content: center;
}

.icons-container {
  width: 60%;
  margin-top: 10px;
}

.state-container {
  background-color: white;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/routes/InvitationDetail/invitationDetail.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[".qr-code-background {\n    border-radius: 4px;\n    margin-top: 18px;\n    width: auto;\n}\n\n.qr-container {\n    display: flex;\n    background-color: white;\n    padding: 14px;\n    justify-content: center;\n}\n\n.icons-container {\n    width: 60%;\n    margin-top: 10px;\n}\n\n.state-container {\n    background-color: white;\n    border-radius: 4px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
