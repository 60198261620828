import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import React from "react";
import api, {
  AccessServer,
  AccessServersResponse,
  CreateUserParams,
  EditUserParams,
  NetworkGroups,
  NetworkGroupsParams,
  StrUserPayload,
  UserDetails,
  Users,
} from "../../api";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import AdminDesktopHeader from "../AdminDesktopHeader";
import { selectStrapiProperty } from "../../slices/strapiProperty";
import { selectStrapiUserRole } from "../../slices/strapiUser";
import { selectStrapiUnit } from "../../slices/strapiUnit";

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface AdminDesktopNewUserProps {
  userType: "residents" | "admin" | "guard";
  functionType: "new" | "edit";
  headerRef: any;
  buttonAction: () => void;
  userById?: Users | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCloseFunction: () => void;
}

const AdminDesktopNewUser = ({
  userType,
  functionType,
  headerRef,
  buttonAction,
  userById,
  open,
  setOpen,
  onCloseFunction,
}: AdminDesktopNewUserProps) => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const property = useAppSelector(selectStrapiProperty);
  const userRole = useAppSelector(selectStrapiUserRole);
  const unit = useAppSelector(selectStrapiUnit);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [addName, setAddName] = React.useState<string>("");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [temporaryEmail, setTemporaryEmail] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [departmentsList, setDepartmentsList] = React.useState<string[]>([]);
  const [completeDepartmentsList, setCompleteDepartmentsList] = React.useState<
    { label: string; value: number }[]
  >([]);
  const [department, setDepartment] = React.useState<string | null>("");
  const [networkGroups, setNetworkGroups] = React.useState<string[] | null>(
    null
  );
  const [selectedNetworkGroup, setSelectedNetworkGroup] =
    React.useState<string>("");
  const [accessServersList, setAccessServersList] = React.useState<
    AccessServer[] | null
  >(null);
  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);
  const [disabledUser, setDisabledUser] = React.useState<boolean>(false);
  const [networkGroupsList, setNetworkGroupsList] =
    React.useState<NetworkGroupsParams | null>(null);
  const [errorPhoneLabel, setErrorPhoneLabel] = React.useState<boolean>(false);
  const [errorPhoneDigits, setErrorPhoneDigits] =
    React.useState<boolean>(false);
  const [errorPhoneLetters, setErrorPhoneLetters] =
    React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);
  const [accessPointsList, setAccessPointsList] = React.useState<
    { label: string; value: number }[]
  >([]);

  // console.log("networkGroups", networkGroups);
  // console.log("finalAccessPointTemporary", finalAccessPointTemporary);

  const departmentId = networkGroupsList?.network_groups.filter(
    (element) => element.name === department
  )[0]?.id;

  const buttonResidentsDisabled =
    !addName || !addEmail || !addPhone || !department;

  const buttonAdminDisabled = !addName || !addEmail || !addPhone;

  const buttonGuardDisabled =
    !addName || !addEmail || finalAccessPointTemporary.length < 1;

  // console.log("user", user);
  // console.log("departmentsList", departmentsList);
  // console.log("completeDepartmentsList", completeDepartmentsList);

  React.useEffect(() => {
    onReloadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onReloadComponent = () => {
    if (open) {
      // setIsLoading(true);
      getInitialValues();
    }
  };

  const getInitialValues = () => {
    setAddEmail("");
    setAddName("");
    setAddPhone("");
    setCountryCode("+52");
    setFinalAccessPointTemporary([]);
    setErrorEmail(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLabel(false);
    setErrorPhoneLetters(false);
  };

  React.useEffect(() => {
    getInitialValuesForEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userById]);

  const getInitialValuesForEdit = () => {
    if (userById) {
      if (userById.name) {
        setAddName(userById.name);
      }
      if (userById.phone) {
        if (userById.phone.startsWith("+52")) {
          setAddPhone(userById.phone.slice(3));
          setCountryCode("+52");
        } else {
          setAddPhone(userById.phone.slice(2));
          setCountryCode("+1");
        }
      }
      if (userById.email) {
        if (userById.email.endsWith("00000")) {
          setTemporaryEmail(userById.email);
          setAddEmail(userById.email.slice(0, -5));
        } else {
          setAddEmail(userById.email);
        }
      }
      if (userById.network_group) {
        setDepartment(userById.network_group);
      }
    }
  };

  const checkNumbers = (str: string) => {
    return /^\d+$/.test(str);
  };

  const validateEmail = (email: string) => {
    const validateCode = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validateCode.test(email);
  };

  const onBlurTextField = (
    parameter: string,
    setParameter: (parameter: string) => void
  ) => {
    const trimName = parameter.trim();
    setParameter(trimName);
  };

  const onBlurEmailField = () => {
    if (addEmail) {
      const trimEmail = addEmail.trim().replace(/\s+/g, "");
      setAddEmail(trimEmail);
      const verifiedEmail = validateEmail(trimEmail);
      if (!verifiedEmail) {
        setErrorEmail(true);
      }
    }
  };

  const onFocusEmailField = () => {
    setErrorEmail(false);
  };

  const onBlurPhoneField = () => {
    if (addPhone) {
      const trimPhone = addPhone.trim().replace(/\s+/g, "");
      setAddPhone(trimPhone);
      const checkOnlyNumbers: boolean = checkNumbers(trimPhone);
      if (trimPhone.length !== 10) {
        setErrorPhoneLabel(true);
        setErrorPhoneDigits(true);
      }
      if (!checkOnlyNumbers) {
        setErrorPhoneLabel(true);
        setErrorPhoneLetters(true);
      }
    }
  };

  const onFocusPhoneField = () => {
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
  };

  React.useEffect(() => {
    getEmailForDisabling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledUser, addEmail]);

  const getEmailForDisabling = () => {
    if (!disabledUser) {
      setTemporaryEmail("");
    } else {
      setTemporaryEmail(`${addEmail}00000`);
    }
  };

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledUser(event.target.checked);
  };

  // React.useEffect(() => {
  //   requestUserList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // React.useEffect(() => {
  //   getNetworkGroups();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  // const getNetworkGroups = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await api.getNetworkGroups(
  //       "",
  //       "",
  //       1,
  //       500
  //     );
  //     setNetworkGroupsList(response);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  React.useEffect(() => {
    getUnits();
    getAccessPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnits = async () => {
    if (property) {
      try {
        setIsLoading(true);
        const response = await api.strapiUnitsByPropertyRequest(property.id);
        if (response) {
          const fixedResponse = response.data.map((item) => {
            return {
              label: item?.attributes?.name,
              value: item?.id,
            };
          });
          if (userRole === "host") {
            const filteredResponse = fixedResponse.filter(
              (item) => item.value === unit?.id
            );
            setCompleteDepartmentsList(filteredResponse);
          } else {
            setCompleteDepartmentsList(fixedResponse);
          }
        }
      } catch (error) {
        console.error(error);
        console.log(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAccessPoints = async () => {
    if (property) {
      try {
        setIsLoading(true);
        const response = await api.strapiAccessPointsByPropertyRequest(
          property.id
        );
        if (response) {
          const fixedResponse = response.data.map((item) => {
            return {
              label: item?.attributes?.address,
              value: item?.id,
            };
          });
          setAccessPointsList(fixedResponse);
        }
      } catch (error) {
        console.error(error);
        console.log(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //END POINT USERS LIST REQUEST------------------------------------------------>>
  //END POINT SOLICITUD DE LISTA DE USUARIOS------------------------------------>>
  // const requestUserList = async () => {
  //   try {
  //     const result = await api.usersListRequest("");
  //     const departments = Array.from(
  //       new Set(
  //         result.users
  //           .filter((user) => user.network_group)
  //           .map((user) => user.network_group?.name)
  //       )
  //     );
  //     setCompleteDepartmentsList(result.users);
  //     // setDepartmentsList(departments);
  //   } catch (error) {
  //     console.error(error);
  //     showError(dispatch, labels.generic_error);
  //   }
  // };

  React.useEffect(() => {
    getNetworkGroupsAndAccessServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getNetworkGroupsAndAccessServers = () => {
    const networkGroups = user?.access_servers.map(
      ({ venue_name }) => venue_name
    );
    const accessServersFiltered = user?.access_servers.map((item) => item);
    if (networkGroups) {
      const networkGroupsNoDuplicated = networkGroups.filter(
        (element, index) => {
          return networkGroups?.indexOf(element) === index;
        }
      );
      if (networkGroupsNoDuplicated) {
        setNetworkGroups(networkGroupsNoDuplicated);
      }
    }
    if (accessServersFiltered) {
      setAccessServersList(accessServersFiltered);
    }
  };

  // React.useEffect(() => {
  //   getNetworkGroups();
  // }, []);

  // const getNetworkGroups = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await api.getNetworkGroups("", "", 1, 100);
  //     setNetworkGroupsList(response);
  //     const departments = response.network_groups.map((item) => item.name);
  //     setDepartmentsList(departments);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onCreateUser = async () => {
    const payload: StrUserPayload = {
      username: addName,
      email: addEmail,
      phone: countryCode + addPhone,
      role: 3,
      unit: departmentId,
      password: "temporal",
    };
    try {
      setIsLoading(true);
      const response = await api.strapiCreateUser(payload);
      if (response) {
        alert(`Se ha mandado un correo de confirmación a ${addName}`);
        setOpen(false);
        setTimeout(() => {
          onCloseFunction();
        }, 400);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const onCreateUser = async () => {
  //   const payload: CreateUserParams = {
  //     name: addName,
  //     email: addEmail,
  //     country_code: countryCode,
  //     role:
  //       userType === "residents"
  //         ? "host"
  //         : userType === "admin"
  //         ? "network_admin"
  //         : "security_guard",
  //   };
  //   if (userType !== "guard") {
  //     payload.phone = addPhone;
  //   }
  //   if (userType === "residents") {
  //     payload.network_group = departmentId;
  //   }
  //   if (userType === "guard") {
  //     payload.access_servers = finalAccessPointTemporary;
  //   }
  //   try {
  //     setIsLoading(true);
  //     const response = await api.createUser(payload);
  //     if (response) {
  //       setOpen(false);
  //       setTimeout(() => {
  //         onCloseFunction();
  //       }, 400);
  //     }
  //   } catch (error: any) {
  //     console.error(error);
  //     console.log(error?.response);
  //     if (error?.response?.status === 401) {
  //       showError(dispatch, labels.not_authorized);
  //     } else if (error?.response?.status === 422) {
  //       if (
  //         error?.response?.data?.error?.message ===
  //         "This email belongs to another account"
  //       ) {
  //         showError(dispatch, labels.this_email_belongs_to_another_account);
  //       } else if (error?.response?.data?.error?.message === "Limit reached") {
  //         showError(dispatch, labels.limit_reached);
  //       } else {
  //         showError(dispatch, labels.wrong_parameters);
  //       }
  //     } else if (error?.response?.status === 403) {
  //       dispatch(resetToken());
  //       dispatch(resetUser());
  //     } else {
  //       showError(dispatch, labels.generic_error);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onEditUser = async () => {
    if (userById) {
      const params: EditUserParams = {
        user: {
          name: addName,
          email: temporaryEmail ? temporaryEmail : addEmail,
          phone: `${countryCode}${addPhone}`,
        },
      };
      try {
        setIsLoading(true);
        await api.editUser(userById.id, params);
        setOpen(false);
        setTimeout(() => {
          onCloseFunction();
        }, 400);
      } catch (error: any) {
        console.error(error);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        width: 700,
        height: "100%",
        boxSizing: "border-box",
        // borderRadius: 4,
        // borderColor: "#e8e8e8",
        // borderStyle: "solid",
        // borderWidth: 1,
        position: "relative",
      }}
    >
      {/* {userType === "residents" && (
        <AdminDesktopHeader
          title={labels.new_host}
          buttonLabel={"REGRESAR"}
          refObject={headerRef}
          buttonAction={buttonAction}
        />
      )}
      {userType === "guard" && (
        <AdminDesktopHeader
          title={labels.new_guard}
          buttonLabel={"REGRESAR"}
          refObject={headerRef}
          buttonAction={buttonAction}
        />
      )}
      {userType === "admin" && (
        <AdminDesktopHeader
          title={labels.new_admin}
          buttonLabel={"REGRESAR"}
          refObject={headerRef}
          buttonAction={buttonAction}
        />
      )} */}
      {/* <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{labels.user_info}</p>
        <p>{labels.license_details}</p>
      </div> */}
      <div
        style={{
          width: "100%",
          // display: "flex",
          boxSizing: "border-box",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        }}
      >
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: 18, marginLeft: 20, marginBottom: 25 }}>
            {labels.user_info}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 4,
              boxSizing: "border-box",
              padding: 30,
              // borderColor: "rgba(196, 196, 196, 0.5)",
              // borderWidth: 1,
              // borderStyle: "solid",
              // boxShadow:
              //   "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div style={{ width: "48%" }}>
                {/* <TextField
                  required
                  value={addName}
                  label={labels.full_name}
                  fullWidth
                  onChange={(e) => {
                    setAddName(e.target.value);
                  }}
                /> */}
                <p style={{ marginBottom: 10, padding: 0 }}>Nombre completo</p>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined"
                    placeholder={labels.name}
                    type="text"
                    value={addName}
                    onChange={(e) => {
                      setAddName(e.target.value);
                    }}
                    onBlur={() => onBlurTextField(addName, setAddName)}
                  />
                </FormControl>
              </div>
              <div style={{ width: "48%" }}>
                {/* <TextField
                  value={addEmail}
                  label={labels.email}
                  fullWidth
                  onChange={(e) => {
                    setAddEmail(e.target.value);
                  }}
                /> */}
                <p style={{ marginBottom: 10, padding: 0 }}>{labels.email}</p>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    id="outlined"
                    placeholder={labels.email}
                    type="text"
                    value={addEmail}
                    onChange={(e) => {
                      setAddEmail(e.target.value);
                    }}
                    onBlur={onBlurEmailField}
                    onFocus={onFocusEmailField}
                    error={errorEmail}
                  />
                </FormControl>
                {errorEmail && (
                  <p
                    className="m-0 p-0 font-12 ml-4 mt-2"
                    style={{ color: "#d32f2f" }}
                  >
                    {labels.error_email}
                  </p>
                )}
              </div>
            </div>
            {userType !== "guard" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    width: "48%",
                  }}
                >
                  <p style={{ marginBottom: 10, padding: 0 }}>
                    {labels.phone_number}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "34%" }}>
                      <FormControl fullWidth>
                        <Select
                          value={countryCode}
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                        >
                          <MenuItem value={"+52"}>MX +52</MenuItem>
                          <MenuItem value={"+1"}>US +1</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ width: "64%" }}>
                      {/* <TextField
                    required
                    value={addPhone}
                    label={labels.phone_number}
                    fullWidth
                    onChange={(e) => {
                      setAddPhone(e.target.value);
                    }}
                  /> */}
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined"
                          placeholder={labels.phone_number}
                          type="tel"
                          value={addPhone}
                          onChange={(e) => {
                            setAddPhone(e.target.value);
                          }}
                          onBlur={onBlurPhoneField}
                          onFocus={onFocusPhoneField}
                          error={errorPhoneLabel}
                        />
                      </FormControl>
                      {errorPhoneLabel && (
                        <>
                          {errorPhoneDigits && (
                            <p
                              className="m-0 p-0 font-12 ml-4 mt-2"
                              style={{ color: "#d32f2f" }}
                            >
                              {labels.ten_digits_error_label}
                            </p>
                          )}
                          {errorPhoneLetters && (
                            <p
                              className="m-0 p-0 font-12 ml-4 mt-2"
                              style={{ color: "#d32f2f" }}
                            >
                              {labels.only_numbers}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ width: "48%" }}>
                  {userType === "residents" && (
                    <>
                      <p style={{ marginBottom: 10, padding: 0 }}>
                        {labels.unit_admin}
                      </p>
                      <FormControl
                        fullWidth
                        style={{ backgroundColor: "white", borderRadius: 4 }}
                      >
                        <Select
                          labelId="host"
                          value={department}
                          onChange={(e) => {
                            setDepartment(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>{labels.unit_admin}</em>
                          </MenuItem>
                          {completeDepartmentsList.map((item, index) => (
                            <MenuItem
                              value={item.value}
                              key={`${index}-${item.value}`}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <Select
                          labelId="host"
                          value={department}
                          onChange={(e) => {
                            setDepartment(e.target.value);
                          }}
                          inputProps={{ "aria-label": "Whithout label" }}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>{labels.unit_admin}</em>
                          </MenuItem>
                          {departmentsList.map((item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </FormControl>
                    </>
                  )}
                </div>
              </div>
            )}
            {userType === "guard" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    width: "48%",
                  }}
                >
                  <p style={{ marginBottom: 10, padding: 0 }}>
                    {labels.access_points}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      padding: 10,
                      borderRadius: 4,
                      borderColor: "#c4c4c4",
                      borderStyle: "solid",
                      borderWidth: 1,
                    }}
                  >
                    {accessPointsList &&
                      accessPointsList.length > 0 &&
                      accessPointsList.map((item) => (
                        <div
                          key={item.value}
                          className="flex align-items-center mb-2"
                        >
                          <Checkbox
                            onChange={() => {
                              let elementFind = finalAccessPointTemporary.find(
                                (element) => element === item.value
                              );
                              let finalIndex =
                                finalAccessPointTemporary.indexOf(item.value);
                              const finalAccessPointTemporaryClone = [
                                ...finalAccessPointTemporary,
                              ];
                              if (!elementFind) {
                                finalAccessPointTemporaryClone.push(item.value);
                              } else {
                                finalAccessPointTemporaryClone.splice(
                                  finalIndex,
                                  1
                                );
                              }
                              setFinalAccessPointTemporary(
                                finalAccessPointTemporaryClone
                              );
                            }}
                            color="secondary"
                          />
                          <div className="flex flex-dir-col">
                            <p className="subtitle-color font-14 m-0 p-0">
                              {item.label}
                            </p>
                            {/* <p className="title-color font-12 m-0 p-0">
                              {venue_name}
                            </p> */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* // <div
                //   style={{
                //     width: "48%",
                //   }}
                // >
                //   <>
                //     <p style={{ marginBottom: 10, padding: 0 }}>Locación</p>
                //     <FormControl
                //       fullWidth
                //       style={{ backgroundColor: "white", borderRadius: 4 }}
                //     >
                //       <Select
                //         labelId="host"
                //         value={department}
                //         onChange={(e) => {
                //           setDepartment(e.target.value);
                //         }}
                //         inputProps={{ "aria-label": "Whithout label" }}
                //         displayEmpty
                //       >
                //         <MenuItem value="">
                //           <em>Locación</em>
                //         </MenuItem>
                //         {departmentsList.map((item, index) => (
                //           <MenuItem value={item} key={index}>
                //             {item}
                //           </MenuItem>
                //         ))}
                //       </Select>
                //     </FormControl>
                //   </>
                // </div>
                // <div style={{ width: "48%" }}>
                //   <>
                //     <p style={{ marginBottom: 10, padding: 0 }}>
                //       Puntos de acceso
                //     </p>
                //     <FormControl
                //       fullWidth
                //       style={{ backgroundColor: "white", borderRadius: 4 }}
                //     >
                //       <Select
                //         labelId="host"
                //         value={department}
                //         onChange={(e) => {
                //           setDepartment(e.target.value);
                //         }}
                //         inputProps={{ "aria-label": "Whithout label" }}
                //         displayEmpty
                //       >
                //         <MenuItem value="">
                //           <em>Puntos de acceso</em>
                //         </MenuItem>
                //         {departmentsList.map((item, index) => (
                //           <MenuItem value={item} key={index}>
                //             {item}
                //           </MenuItem>
                //         ))}
                //       </Select>
                //     </FormControl>
                //   </>
                // </div> */}
              </div>
            )}
            {userType === "residents" && functionType === "edit" && (
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 56,
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        fontSize: 18,
                        margin: 0,
                        padding: 0,
                        marginRight: 10,
                      }}
                    >
                      {labels.deactivate_user}
                    </p>
                  </div>
                  <TemporarySwitch
                    checked={disabledUser}
                    onChange={onChangeSwitch}
                    disabled={isLoading}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <div style={{ width: "48%" }}></div>
              <div style={{ width: "48%" }}>
                <Button
                  disabled={
                    userType === "residents"
                      ? buttonResidentsDisabled
                      : userType === "admin"
                      ? buttonAdminDisabled
                      : buttonGuardDisabled
                  }
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ height: 56 }}
                  onClick={functionType === "edit" ? onEditUser : onCreateUser}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 18 }}>{labels.license_details}</p>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: 230,
              boxSizing: "border-box",
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                marginBottom: 6,
              }}
            >
              <p className="m-0 p-0">{labels.current_plan}:</p>
              <p
                className="text-secondary-color m-0 p-0"
                style={{ fontWeight: "bold" }}
              >
                {user?.network.license.license_type_id}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                marginBottom: 6,
              }}
            >
              <p className="m-0 p-0">{labels.available_admin}:</p>
              <p
                className="text-secondary-color m-0 p-0"
                style={{ fontWeight: "bold" }}
              >
                {user?.network.license.max_network_admins}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                marginBottom: 6,
              }}
            >
              <p className="m-0 p-0">{labels.available_hosts}:</p>
              <p
                className="text-secondary-color m-0 p-0"
                style={{ fontWeight: "bold" }}
              >
                {user?.network.license.max_hosts}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <p className="m-0 p-0">{labels.available_guards}:</p>
              <p
                className="text-secondary-color m-0 p-0"
                style={{ fontWeight: "bold" }}
              >
                {user?.network?.license?.max_secondary_users_per_group}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AdminDesktopNewUser;
