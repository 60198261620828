import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  FaAddressCard,
  FaAddressBook,
  FaBook,
  FaChartArea,
  FaDoorOpen,
  FaKeyboard,
  FaUsers,
  FaUser,
  FaUserCircle,
  FaUserCog,
  FaUserShield,
} from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import Logo from "../Logo";
import { selectUser } from "../../slices/user";
import { HiSquares2X2 } from "react-icons/hi2";
import { IoQrCodeSharp } from "react-icons/io5";
import { selectStrapiUserRole } from "../../slices/strapiUser";
import { RiOrganizationChart } from "react-icons/ri";

interface AdminLeftMenuProps {
  selectedPage: string;
  setSelectedPage: (selectedPage: string) => void;
}

const AdminLeftMenu = ({
  selectedPage,
  setSelectedPage,
}: AdminLeftMenuProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectStrapiUserRole);

  const [showProfileOptions, setShowProfileOptions] =
    React.useState<boolean>(false);
  const [showVisitorsOptions, setShowVisitorsOptions] =
    React.useState<boolean>(true);
  const [showSecurityOptions, setShowSecurityOptions] =
    React.useState<boolean>(false);
  const [showSettingsOptions, setShowSettingsOptions] =
    React.useState<boolean>(false);

  const profileOptions = [
    { id: 12, label: labels.my_profile, value: "profile" },
    { id: 13, label: labels.logout, value: "logout" },
  ];
  const visitorsOptions = [
    { id: 8, label: labels.logbook, value: "logbook" },
    { id: 9, label: labels.events_label, value: "events" },
    { id: 10, label: labels.invitations, value: "invitations" },
    // { id: 11, label: labels.registered, value: "registered" }, //CODIGO VENTANA REGISTRADOS
  ];
  const securityOptions = [
    // { id: 6, label: labels.logbook, value: "logbook" },
    { id: 7, label: labels.intelligence, value: "intelligence" },
  ];
  const settingsOptionsAdmin = [
    { id: 5, label: labels.access_points, value: "access_points" },
    { id: 1, label: labels.unit, value: "departments" },
    { id: 6, label: labels.organizational_areas, value: "organizational_area" },
    { id: 2, label: labels.users_admin, value: "users" },
    { id: 3, label: labels.guards, value: "guard" },
    { id: 4, label: labels.admins, value: "admin" },
    // { id: 3, label: labels.users_event, value: "users" },
    // { id: 4, label: labels.custom_attributes, value: "custom_attributes" },
  ];

  const settingsOptionsHost = [
    { id: 2, label: labels.users_admin, value: "users" },
    // { id: 3, label: labels.users_event, value: "users" },
    // { id: 4, label: labels.custom_attributes, value: "custom_attributes" },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // borderRightColor: "rgba(16,65,138, 1)",
        borderRightColor: "rgba(196, 196, 196, 0.5)",
        borderRightStyle: "solid",
        borderRightWidth: 8,
      }}
    >
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {/* {user?.network?.logo_url !== null ? (
            <div>
              <img
                src={user?.network?.logo_url}
                alt={"NetworkLogo"}
                className="admin-logo-passtrack"
              />
            </div>
          ) : (
            <Logo
              type="passtrackLogo"
              alt="Passtrack"
              className="admin-logo-passtrack"
            />
          )} */}
          <Logo
            type="passtrackLogo"
            alt="Passtrack"
            className="admin-logo-passtrack"
          />
        </div>
        {/* CODIGO PARA MENU DE INTELIGENCIA-------------------------------------->> */}
        {userRole === "admin" && (
          <div
            style={{
              width: "100%",
              minHeight: 46,
              boxSizing: "border-box",
              margin: 0,
              paddingTop: 10,
              paddingBottom: 10,
              paddingRight: 10,
              paddingLeft: selectedPage === "intelligence" ? 10 : 16,
              display: "flex",
              alignItems: "center",
              backgroundColor: selectedPage === "intelligence" ? "#e8e8e8" : "",
              borderLeftColor: selectedPage === "intelligence" ? "#10418a" : "",
              borderLeftStyle:
                selectedPage === "intelligence" ? "solid" : "none",
              borderLeftWidth: selectedPage === "intelligence" ? 6 : 0,
              cursor: "pointer",
            }}
            onClick={() => setSelectedPage("intelligence")}
          >
            <FaChartArea
              fontSize={24}
              color={selectedPage === "intelligence" ? "#10418a" : ""}
            />
            <p
              className="m-0 p-0 ml-5 font-16"
              style={{
                color: selectedPage === "intelligence" ? "#10418a" : "",
              }}
            >
              {labels.intelligence}
            </p>
          </div>
        )}
        <div
          style={{
            width: "100%",
            minHeight: 46,
            boxSizing: "border-box",
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 10,
            paddingLeft: showVisitorsOptions ? 10 : 16,
            display: "flex",
            alignItems: "center",
            backgroundColor: showVisitorsOptions ? "#e8e8e8" : "",
            borderLeftColor: showVisitorsOptions ? "#10418a" : "",
            borderLeftStyle: showVisitorsOptions ? "solid" : "none",
            borderLeftWidth: showVisitorsOptions ? 6 : 0,
            cursor: "pointer",
          }}
          onClick={() => setShowVisitorsOptions(!showVisitorsOptions)}
        >
          <FaAddressCard fontSize={24} />
          <p className="m-0 p-0 ml-5 font-16">{labels.visitors}</p>
        </div>
        {showVisitorsOptions && (
          <div>
            {visitorsOptions.map(({ id, value, label }) => (
              <div
                key={id}
                style={{
                  width: "100%",
                  minHeight: 46,
                  boxSizing: "border-box",
                  margin: 0,
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 34,
                  cursor: "pointer",
                }}
                onClick={() => setSelectedPage(value)}
              >
                {value === "logbook" && (
                  <FaBook
                    fontSize={20}
                    color={selectedPage === value ? "#10418a" : ""}
                  />
                )}
                {value === "events" && (
                  <FaUsers
                    fontSize={20}
                    color={selectedPage === value ? "#10418a" : ""}
                  />
                )}
                {value === "invitations" && (
                  <IoQrCodeSharp
                    fontSize={20}
                    color={selectedPage === value ? "#10418a" : ""}
                  />
                )}
                {/* CODIGO PARA OPCION REGISTRADOS------------------------------------>> */}
                {/* {value === "registered" && (
                <FaAddressBook
                  fontSize={20}
                  color={selectedPage === value ? "#10418a" : ""}
                />
              )} */}
                <p
                  className="m-0 p-0 ml-5"
                  style={{ color: selectedPage === value ? "#10418a" : "" }}
                >
                  {label}
                </p>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            width: "100%",
            minHeight: 46,
            boxSizing: "border-box",
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 10,
            paddingLeft: showSettingsOptions ? 10 : 16,
            display: "flex",
            alignItems: "center",
            backgroundColor: showSettingsOptions ? "#e8e8e8" : "",
            borderLeftColor: showSettingsOptions ? "#10418a" : "",
            borderLeftStyle: showSettingsOptions ? "solid" : "none",
            borderLeftWidth: showSettingsOptions ? 6 : 0,
            cursor: "pointer",
          }}
          onClick={() => setShowSettingsOptions(!showSettingsOptions)}
        >
          <AiFillSetting fontSize={26} />
          {/* <p className="m-0 p-0 ml-5 font-16">{labels.additions_and_deletions}</p> */}
          <p className="m-0 p-0 ml-5 font-16">{labels.settings}</p>
        </div>
        {showSettingsOptions && (
          <>
            {userRole === "admin" ? (
              <div>
                {settingsOptionsAdmin.map(({ id, value, label }) => (
                  <div
                    key={id}
                    style={{
                      width: "100%",
                      minHeight: 46,
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 34,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedPage(value)}
                  >
                    {value === "users" && (
                      <FaUser
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "admin" && (
                      <FaUserCog
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "guard" && (
                      <FaUserShield
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "access_points" && (
                      <FaDoorOpen
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "departments" && (
                      <HiSquares2X2
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "organizational_area" && (
                      <RiOrganizationChart
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {/* {value === "users" && (
                <FaUsers
                  fontSize={20}
                  color={selectedPage === value ? "#10418a" : ""}
                />
              )} */}
                    {value === "custom_attributes" && (
                      <FaKeyboard
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "profile" && (
                      <FaUserCircle
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    <p
                      className="m-0 p-0 ml-5"
                      style={{ color: selectedPage === value ? "#10418a" : "" }}
                    >
                      {label}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {settingsOptionsHost.map(({ id, value, label }) => (
                  <div
                    key={id}
                    style={{
                      width: "100%",
                      minHeight: 46,
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 34,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedPage(value)}
                  >
                    {value === "users" && (
                      <FaUser
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "admin" && (
                      <FaUserCog
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "guard" && (
                      <FaUserShield
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "access_points" && (
                      <FaDoorOpen
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "departments" && (
                      <HiSquares2X2
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {/* {value === "users" && (
                <FaUsers
                  fontSize={20}
                  color={selectedPage === value ? "#10418a" : ""}
                />
              )} */}
                    {value === "custom_attributes" && (
                      <FaKeyboard
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    {value === "profile" && (
                      <FaUserCircle
                        fontSize={20}
                        color={selectedPage === value ? "#10418a" : ""}
                      />
                    )}
                    <p
                      className="m-0 p-0 ml-5"
                      style={{ color: selectedPage === value ? "#10418a" : "" }}
                    >
                      {label}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {userRole === "admin" && (
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <a href="https://app.passtrack.mx/users/sign_in" target="_self">
            <p
              className="primary-color"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {labels.use_previous_version}
            </p>
          </a>
        </div>
      )}
    </div>
  );
};

export default AdminLeftMenu;
