// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-photo-style {
  max-width: 300px;
  max-height: 300px;
}

.transaction-photo-style-desktop {
  max-width: 100%;
  max-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/routes/TransactionIdDetail/transaction-id.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".transaction-photo-style {\n    max-width: 300px;\n    max-height: 300px;\n}\n\n.transaction-photo-style-desktop {\n    max-width: 100%;\n    max-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
