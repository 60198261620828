// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-logo {
  height: 50px;
}

.desktop-logo {
  height: 75px;
}

.network-logo {
  height: 45px;
}

.menu-bar {
  height: 60px;
}

.menu-bar-guard {
  height: 60px;
}

.indicator {
  width: 5px;
}
.indicator-selected {
  background: var(--main-primary-color);
}

.bg-selected {
  background-color: var(--main-header-color);
}

.menu-item:hover .indicator {
  background: var(--main-primary-lighter-color);
}
.menu-item:hover .indicator-selected {
  background: var(--main-primary-color);
}

.paper-no-style {
  background-color: white !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMenu/mobile-menu.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;AACF;AAAE;EACE,qCAAA;AAEJ;;AAEA;EACE,0CAAA;AACF;;AAII;EACE,6CAAA;AADN;AAEM;EACE,qCAAA;AAAR;;AAMA;EACE,kCAAA;EACA,2BAAA;AAHF","sourcesContent":[".mobile-logo {\n  height: 50px;\n}\n\n.desktop-logo {\n  height: 75px;\n}\n\n.network-logo {\n  height: 45px;\n}\n\n.menu-bar {\n  height: 60px;\n}\n\n.menu-bar-guard {\n  height: 60px;\n}\n\n.indicator {\n  width: 5px;\n  &-selected {\n    background: var(--main-primary-color);\n  }\n}\n\n.bg-selected {\n  background-color: var(--main-header-color);\n}\n\n.menu-item {\n  &:hover {\n    .indicator {\n      background: var(--main-primary-lighter-color);\n      &-selected {\n        background: var(--main-primary-color);\n      }\n    }\n  }\n}\n\n.paper-no-style {\n  background-color: white !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
