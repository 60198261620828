import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import api, { StrapiLoginPayload } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Logo from "../../components/Logo";
import { selectLabels } from "../../slices/language";
import { gettingToken, setToken } from "../../slices/token";
import { setUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import "./login.scss";
import { isMobile } from "react-device-detect";
import { setStrapiUser } from "../../slices/strapiUser";
import { StrapiUserPayload } from "../../strapiModels/strapiUser";
import { setStrapiUnit } from "../../slices/strapiUnit";
import { setStrapiProperty } from "../../slices/strapiProperty";

const Login = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState(localStorage.getItem("email") || "");
  const [password, setPassword] = React.useState(
    localStorage.getItem("password") || ""
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rememberPassword, setRememberPassword] = React.useState(
    !!localStorage.getItem("email") && !!localStorage.getItem("password")
  );
  const isMounted = React.useRef(true);

  const emailAdjusted = email.trim().toLowerCase();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onSubmit = async () => {
    dispatch(gettingToken());
    try {
      setIsLoading(true);
      const { jwt, user } = await api.strapiLoginRequest({
        identifier: emailAdjusted,
        password,
      });
      if (jwt && user) {
        const userData = await api.strapiUserByIdRequest(user.id, jwt);
        if (userData) {
          if (userData?.unit?.id) {
            getUnitById(userData?.unit?.id, jwt);
          }
          if (isMounted.current) {
            if (rememberPassword) {
              localStorage.setItem("email", email);
              localStorage.setItem("password", password);
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
            }
            dispatch(setToken(jwt));
            dispatch(setStrapiUser(userData));
            if (userData?.role?.id === 4) {
              navigate("/qr-scanner");
            }
            if (userData?.role?.id === 6 && isMobile) {
              navigate("/invitations");
            }
            if (userData?.role?.id === 6 && !isMobile) {
              navigate("/");
            }
            if (userData?.role?.id === 3 && isMobile) {
              navigate("/invitations");
            }
            if (userData?.role?.id === 3 && !isMobile) {
              navigate("/");
            }
            if (userData?.role?.id === 5) {
              navigate("/invitations");
            }
          }
        }
      }
    } catch (error: any) {
      console.error(error);
      console.log("error", error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUnitById = async (id: number, token: string) => {
    if (id) {
      try {
        setIsLoading(true);
        const response = await api.strapiUnitByIdRequest(id, token);
        if (response) {
          dispatch(setStrapiUnit(response.data));
          if (response?.data?.attributes?.property?.data?.id) {
            getPropertyById(
              response?.data?.attributes?.property?.data?.id,
              token
            );
          }
        }
      } catch (error) {
        console.error(error);
        console.log("error", error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getPropertyById = async (id: number, token: string) => {
    if (id) {
      try {
        setIsLoading(true);
        const response = await api.strapiPropertyByIdRequest(id, token);
        if (response) {
          dispatch(setStrapiProperty(response.data));
        }
      } catch (error) {
        console.error(error);
        console.log("error", error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Container maxWidth="xs" className="my-7">
      <Paper
        variant="outlined"
        className="p-10 vh-85 flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
      >
        <Logo type="passtrackLogo" alt="passtrack" className="logo" />
        <p className="subtitle-color medium font-28 mt-0">{labels.login}</p>
        <TextField
          className="mt-8"
          label={labels.email_address}
          variant="outlined"
          fullWidth
          placeholder="john@mail.com"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? "text" : "password"}
          label={labels.password}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
        />
        <div className="full-width">
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberPassword}
                onChange={() => {
                  setRememberPassword(!rememberPassword);
                }}
              />
            }
            label={labels.remember_password}
          />
        </div>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          onClick={onSubmit}
          disabled={!email || !password || isLoading}
          className="py-6"
        >
          {isLoading ? <CircularProgress size={26.5} /> : labels.login}
        </Button>
        <p>
          <Link to="/restore-password">{labels.fogot_the_password}</Link>
        </p>
      </Paper>
    </Container>
  );
};

export default Login;
