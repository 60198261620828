// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dg-header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--main-primary-color);
}

.dg-header-two {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.dg-main-containers {
  width: 33.3333333333%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.dg-blue-color {
  color: var(--main-primary-color);
}

.header-selector {
  background-color: white;
}

.header-logo-size-1 {
  height: 30px;
  padding: 5px;
}

.header-logo-size-2 {
  height: 25px;
}

.admin-logo-passtrack {
  height: 70px;
}`, "",{"version":3,"sources":["webpack://./src/routes/DesktopGuard/desktop-guard.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,2CAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,sBAAA;AACF;;AAEA;EACE,qBAAA;EACA,0BAAA;EACA,sBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".dg-header {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  box-sizing: border-box;\n  padding-left: 20px;\n  padding-right: 20px;\n  background-color: var(--main-primary-color);\n}\n\n.dg-header-two {\n  height: 60px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  box-sizing: border-box;\n}\n\n.dg-main-containers {\n  width: calc(100% / 3);\n  height: calc(100vh - 60px);\n  box-sizing: border-box;\n  background-color: #f5f5f5;\n}\n\n.dg-blue-color {\n  color: var(--main-primary-color);\n}\n\n.header-selector {\n  background-color: white;\n}\n\n.header-logo-size-1 {\n  height: 30px;\n  padding: 5px;\n}\n\n.header-logo-size-2 {\n  height: 25px;\n}\n\n.admin-logo-passtrack {\n  height: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
