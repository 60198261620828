// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-border {
  border: 4px #dfdfdf solid;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/DynamicKey/dynamic-key.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;AACJ","sourcesContent":[".qr-border {\n    border: 4px #dfdfdf solid;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
