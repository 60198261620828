// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-sub-tag {
  background: var(--main-gray-color);
}

.button-paper-style {
  height: auto;
}

.paper-text {
  font-size: 28px;
  margin: 0;
}

.paper-text-mobile {
  font-size: 18px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/routes/QrScanner/qrscanner.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,SAAA;AACJ","sourcesContent":[".div-sub-tag {\n    background: var(--main-gray-color);\n}\n\n.button-paper-style {\n    height: auto;\n}\n\n.paper-text {\n    font-size: 28px;\n    margin: 0;\n}\n\n.paper-text-mobile {\n    font-size: 18px;\n    margin: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
