import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import { showError } from "../../utils/helper";
import api, { GetAccessKeys, GetAccessKeysAccessKeys } from "../../api";
import { resetToken, selectToken } from "../../slices/token";
import "./invitations-refactor.scss";
import { useNavigate } from "react-router-dom";
import InvitationList from "../../components/InvitationList";
import MainPagesHeader from "../../components/MainPagesHeader";
import Pagination from "../../components/Pagination";
import Filters from "../../components/Filters";
import { FaSignInAlt, FaSignOutAlt, FaLock, FaEnvelope } from "react-icons/fa";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment-timezone";
import ModalToNativeApp from "../../components/ModalToNativeApp";
import { selectStrapiUser } from "../../slices/strapiUser";

interface FileToExport {
  "tipo de invitación": string;
  vigencia: string;
  "nombre del visitante": string;
  anfitrión: string;
  departamento: string;
  estatus: string;
  "puntos de acceso": string;
  eliminada: string;
}

const InvitationsRefactor = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  const user = useAppSelector(selectStrapiUser);

  console.log('user', user)

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [invitationsList, setInvitationsList] =
    React.useState<GetAccessKeys | null>(null);
  const [date, setDate] = React.useState<
    Date | null | "last_week" | "last_month"
  >(null);
  const [dateRefactor, setDateRefactor] = React.useState<string>("all");
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [statusFilter, setStatusFilter] = React.useState<
    "access" | "exit" | "try" | "unknown" | null
  >(null);
  const [statusFilterParams, setStatusFilterParams] = React.useState<string>(
    labels.all
  );
  const [invitationType, setInvitationType] = React.useState<string>("all");
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [arrayFilters, setArrayFilters] = React.useState<string>(
    labels.date_filter
  );
  const [filterContent, setFilterContent] = React.useState<any>(null);
  const [secUserInvitations, setSecUserInvitations] = React.useState<
    GetAccessKeysAccessKeys[] | null
  >(null);
  const [secUserInvitationsBackUp, setSecUserInvitationsBackUp] =
    React.useState<GetAccessKeysAccessKeys[] | null>(null);
  const [secUserPage, setSecUserPage] = React.useState<number>(1);
  const [secUserRowsPerPage, setSecUserRowsPerPage] =
    React.useState<number>(10);
  const [secUserTotalPages, setSecUserTotalPages] = React.useState<number>(0);

  const filtersArray: any[] = [
    labels.date_filter,
    labels.status_filter,
    labels.type_filter,
  ];

  const currentDate = new Date();
  const fileName = `${labels.invitations} - ${moment(currentDate).format(
    "LL"
  )}`;

  const startIndex = (secUserPage - 1) * secUserRowsPerPage;
  const endIndex = startIndex + secUserRowsPerPage;
  const currentItems = secUserInvitationsBackUp
    ? secUserInvitationsBackUp.slice(startIndex, endIndex)
    : "";

  React.useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters]);

  const resetFilters = () => {
    if (!showFilters) {
      setStatusFilterParams(labels.all);
      setStatusFilter(null);
      setDateRefactor("all");
      setDate(null);
      setInvitationType("all");
    }
  };

  React.useEffect(() => {
    initialPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, searchInput, invitationType, statusFilter]);

  const initialPage = () => {
    if (userRole === "secondary_host") {
      setSecUserPage(1);
    } else {
      setPage(1);
    }
  };

  const setStatusFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={statusFilterParams}
            onChange={handleInvitationStatusChange}
          >
            <MenuItem value={labels.all}>{labels.all}</MenuItem>
            <MenuItem value={"unknown"}>
              <div className="flex align-items-center">
                {
                  <FaEnvelope
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.invitations_with_not_registration}
              </div>
            </MenuItem>
            <MenuItem value={"access"}>
              <div className="flex align-items-center">
                {
                  <FaSignInAlt
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.with_access_register}
              </div>
            </MenuItem>
            <MenuItem value={"exit"}>
              <div className="flex align-items-center">
                {
                  <FaSignOutAlt
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.with_exit_register}
              </div>
            </MenuItem>
            <MenuItem value={"try"}>
              <div className="flex align-items-center">
                {
                  <FaLock
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.with_unauthorized_attempts}
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };

  const setTypeFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={invitationType}
            onChange={handleInvitationTypeChange}
          >
            <MenuItem value="all">{labels.all}</MenuItem>
            <MenuItem value={"visitor"}>{labels.visitor}</MenuItem>
            <MenuItem value={"temporary"}>{labels.temporary}</MenuItem>
            <MenuItem value={"provider"}>{labels.provider}</MenuItem>
            <MenuItem value={"passtrack_event"}>{labels.event}</MenuItem>
            <MenuItem value={"personal"}>{labels.user_key}</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };

  const setDateFilterFunction = () => {
    let datePicker = (
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            value={date}
            onChange={(newDate: Date | null) => {
              setDate(newDate);
              setPage(1);
            }}
            inputFormat="DD/MMM"
            className="primary-color"
            renderInput={({ inputRef, inputProps }) => (
              <input
                type={"button"}
                ref={inputRef}
                {...inputProps}
                className={
                  date !== null
                    ? "m-0 p-0 py-4 date-filter font-14 primary-color"
                    : "m-0 p-0 py-4 date-filter-null font-14"
                }
              />
            )}
          />
        </LocalizationProvider>
        {date === null && (
          <div className="no-date-filter font-14 primary-color">
            {labels.choose}
          </div>
        )}
      </div>
    );
    setFilterContent(
      <>
        {dateRefactor !== "calendar" ? (
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white", borderColor: "blue" }}
              style={{ color: "#10418a" }}
              value={dateRefactor}
              onChange={dateChange}
            >
              <MenuItem value={"all"}>{labels.all}</MenuItem>
              <MenuItem value={"today"}>{labels.today}</MenuItem>
              <MenuItem value={"last week"}>{labels.last_week}</MenuItem>
              <MenuItem value={"last month"}>{labels.last_month}</MenuItem>
              <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <div className="flex flex-dir-row justify-content-space-between align-items-center">
            <div style={{ width: "47.5%" }}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{ bgcolor: "white", borderColor: "blue" }}
                  style={{ color: "#10418a" }}
                  value={dateRefactor}
                  onChange={dateChange}
                >
                  <MenuItem value={"all"}>{labels.all}</MenuItem>
                  <MenuItem value={"today"}>{labels.today}</MenuItem>
                  <MenuItem value={"last week"}>{labels.last_week}</MenuItem>
                  <MenuItem value={"last month"}>{labels.last_month}</MenuItem>
                  <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              className="flex justify-content-flex-end"
              style={{ width: "47.5%" }}
            >
              {datePicker}
            </div>
          </div>
        )}
      </>
    );
  };

  const dateChange = (event: SelectChangeEvent) => {
    setDateRefactor(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
    }
    if (event.target.value === "last week") {
      setDate("last_week");
    }
    if (event.target.value === "last month") {
      setDate("last_month");
    }
    if (event.target.value === "calendar") {
      setDate(null);
    }
  };

  React.useEffect(() => {
    rightContentManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayFilters, statusFilter, invitationType, dateRefactor, date]);

  const rightContentManager = () => {
    if (arrayFilters === labels.date_filter) {
      setDateFilterFunction();
    }
    if (arrayFilters === labels.status_filter) {
      setStatusFilterFunction();
    }
    if (arrayFilters === labels.type_filter) {
      setTypeFilterFunction();
    }
  };

  const handleInvitationTypeChange = (event: SelectChangeEvent) => {
    setInvitationType(event.target.value);
  };

  const handleInvitationStatusChange = (event: SelectChangeEvent) => {
    setStatusFilterParams(event.target.value);
    if (event.target.value === labels.all) {
      setStatusFilter(null);
    }
    if (event.target.value === "unknown") {
      setStatusFilter("unknown");
    }
    if (event.target.value === "access") {
      setStatusFilter("access");
    }
    if (event.target.value === "exit") {
      setStatusFilter("exit");
    }
    if (event.target.value === "try") {
      setStatusFilter("try");
    }
  };

  React.useEffect(() => {
    if (secUserInvitationsBackUp) {
      setSecUserTotalPages(Math.ceil(secUserInvitationsBackUp?.length / secUserRowsPerPage));
    }
  }, [secUserInvitationsBackUp, secUserRowsPerPage]);

  // END POINT INVITATIONS LIST------------------------------------------------>>
  // END POINT LISTA DE INVITACIONES------------------------------------------->>
  React.useEffect(() => {
    getInvitationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    searchInput,
    date,
    statusFilter,
    invitationType,
  ]);

  const getInvitationsData = async () => {
    try {
      if (token) {
        setIsLoading(true);
        if (userRole === "secondary_host") {
          const result = await api.invitationsRequest(
            1,
            500,
            searchInput,
            date ? `${date}` : "",
            statusFilter,
            invitationType !== "all" ? invitationType : ""
          );
          const filteredResult = result.access_keys.filter(
            (item) => item?.user?.name === user?.username
          );
          setSecUserInvitations(filteredResult.slice(0, secUserRowsPerPage));
          setSecUserInvitationsBackUp(filteredResult);
          // setSecUserTotalPages(
          //   Math.round(filteredResult.length / secUserRowsPerPage)
          // );
        }
      }
      if (userRole !== "secondary_host") {
        const result = await api.invitationsRequest(
          page,
          rowsPerPage,
          searchInput,
          date ? `${date}` : "",
          statusFilter,
          invitationType !== "all" ? invitationType : ""
        );
        setInvitationsList(result);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    arrayToFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, searchInput, date, statusFilter, invitationType]);

  const arrayToFile = async () => {
    // const newArray: FileExport[] = [];
    try {
      if (token) {
        const result = await api.invitationsRequest(
          1,
          100,
          searchInput,
          date ? `${date}` : "",
          statusFilter,
          invitationType !== "all" ? invitationType : ""
        );
        setFileArray(
          result.access_keys.map(
            ({
              user,
              access_key_type_id,
              valid_from_date,
              valid_until_date,
              visitor_name,
              last_transaction_type,
              access_servers,
              deleted_at,
            }) => ({
              "tipo de invitación":
                access_key_type_id === 1
                  ? "visitante"
                  : access_key_type_id === 2
                  ? "recurrente"
                  : access_key_type_id === 3
                  ? "evento"
                  : access_key_type_id === 4
                  ? "evento"
                  : access_key_type_id === 5
                  ? "proveedor"
                  : "visitante",
              vigencia: `${valid_from_date} - ${valid_until_date}`,
              "nombre del visitante": visitor_name,
              anfitrión: `${user?.name}`,
              departamento: `${user?.network_group?.name}`,
              estatus:
                last_transaction_type !== null
                  ? last_transaction_type === "access"
                    ? "acceso"
                    : last_transaction_type === "exit"
                    ? "salida"
                    : last_transaction_type === "try"
                    ? "denegado"
                    : "sin usar"
                  : "sin usar",
              "puntos de acceso": access_servers
                .map(({ name }) => name)
                .join(", "),
              eliminada: deleted_at ? "si" : "",
            })
          )
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  //RENDER---------------------------------------------------------------------->>
  //RENDER---------------------------------------------------------------------->>
  return (
    <>
      {userRole === "host" && <ModalToNativeApp />}
      <Container className={!showFilters ? "padding-out" : "padding-in"}>
        <MainPagesHeader
          newIcon={userRole === "guard" ? false : true}
          onPressNewIconAction={() => navigate(`/new-invitation/:cmId`)}
          mainTitle="invitations"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={true}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          downloadable={userRole === "guard" ? false : true}
          fileArray={fileArray ? fileArray : []}
          fileName={fileName}
        />
        <Filters
          show={showFilters}
          array={filtersArray}
          value={arrayFilters}
          setValue={setArrayFilters}
          rightContent={filterContent}
        />

        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {userRole === "secondary_host" ? (
          <>
            {isLoading ? (
              <div
                className="flex justify-content-center align-items-center w-100"
                style={{ marginTop: 100 }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                className="w-100 flex flex-dir-col justify-content-center"
                style={{
                  paddingBottom: secUserTotalPages ? 200 : 74,
                }}
              >
                {currentItems && currentItems?.length > 0 ? (
                  currentItems.map(
                    ({
                      visitor_name,
                      user,
                      valid_from,
                      valid_until,
                      access_key_type_id,
                      last_transaction_type,
                      time_zone_name,
                      id,
                      deleted_at,
                    }) => (
                      <div
                        key={id}
                        className="w-100 flex justify-content-center mt-5"
                      >
                        <InvitationList
                          statusIcon={last_transaction_type}
                          visitorName={visitor_name}
                          hostName={`${user?.name}`}
                          department={`${user?.network_group?.name}`}
                          date={
                            moment(valid_from)
                              ?.tz(time_zone_name)
                              ?.format("DD MMMM") ===
                            moment(valid_until)
                              .tz(time_zone_name)
                              ?.format("DD MMMM")
                              ? `${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_until)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )}`
                              : `${moment(valid_from)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_from)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_from)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )} - ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_until)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )}`
                          }
                          invitationType={access_key_type_id}
                          onPress={() => navigate(`/invitation-detail/${id}`)}
                          startHour={moment(valid_from)
                            .tz(time_zone_name)
                            .format("hh:mma")}
                          endHour={moment(valid_until)
                            .tz(time_zone_name)
                            .format("hh:mma")}
                          isDeleted={null}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div className="w-100 flex justify-content-center">
                    <p className="font-16 subtitle-color">
                      {labels.no_results_found}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <div
                className="flex justify-content-center align-items-center w-100"
                style={{ marginTop: 100 }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                className="w-100 flex flex-dir-col justify-content-center"
                style={{
                  paddingBottom: invitationsList?.total_pages ? 200 : 74,
                }}
              >
                {invitationsList && invitationsList?.access_keys?.length > 0 ? (
                  invitationsList?.access_keys.map(
                    ({
                      visitor_name,
                      user,
                      valid_from,
                      valid_until,
                      access_key_type_id,
                      last_transaction_type,
                      time_zone_name,
                      id,
                      deleted_at,
                    }) => (
                      <div
                        key={id}
                        className="w-100 flex justify-content-center mt-5"
                      >
                        <InvitationList
                          statusIcon={last_transaction_type}
                          visitorName={visitor_name}
                          hostName={`${user?.name}`}
                          department={`${user?.network_group?.name}`}
                          date={
                            moment(valid_from)
                              ?.tz(time_zone_name)
                              ?.format("DD MMMM") ===
                            moment(valid_until)
                              .tz(time_zone_name)
                              ?.format("DD MMMM")
                              ? `${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_until)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )}`
                              : `${moment(valid_from)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_from)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_from)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )} - ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`DD`)} ${moment(valid_until)
                                  ?.tz(time_zone_name)
                                  ?.format(`MMMM`)
                                  ?.replace(
                                    /^./,
                                    moment(valid_until)
                                      ?.tz(time_zone_name)
                                      ?.format(`MMMM`)[0]
                                      ?.toUpperCase()
                                  )}`
                          }
                          invitationType={access_key_type_id}
                          onPress={() => navigate(`/invitation-detail/${id}`)}
                          startHour={moment(valid_from)
                            .tz(time_zone_name)
                            .format("hh:mma")}
                          endHour={moment(valid_until)
                            .tz(time_zone_name)
                            .format("hh:mma")}
                          isDeleted={null}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div className="w-100 flex justify-content-center">
                    <p className="font-16 subtitle-color">
                      {labels.no_results_found}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Container>
      {userRole === "secondary_host" ? (
        <>
          {secUserTotalPages ? (
            <Pagination
              rowsPerPage={secUserRowsPerPage}
              setRowsPerPage={setSecUserRowsPerPage}
              page={secUserPage}
              setPage={setSecUserPage}
              totalPages={secUserTotalPages}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {invitationsList?.total_pages ? (
            <Pagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPages={invitationsList?.total_pages}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default InvitationsRefactor;
