import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { StrapiOrganizationalAreasArray } from "../../strapiModels/organizationalAreas";
import api from "../../api";

const AdminDesktopOrganizationalArea = () => {
  const labels = useAppSelector(selectLabels);

  const [organizationalAreas, setOrganizationalAreas] =
    React.useState<StrapiOrganizationalAreasArray | null>(null);

  const headerRef: any = React.useRef(null);

  React.useEffect(() => {
    getOrganizationalAreas();
  }, []);

  const getOrganizationalAreas = async () => {
    try {
      const response = await api.strapiOrganizationalAreasRequest();
      if (response) {
        setOrganizationalAreas(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="admin-desktop-invitations-main-container">
      <AdminDesktopHeader
        title={labels.organizational_areas}
        buttonLabel={""}
        refObject={headerRef}
      />
    </div>
  );
};

export default AdminDesktopOrganizationalArea;
