import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import api, {
  AccessPointPayload,
  AccessServerResponse,
  UpdateAccessServerParams,
} from "../../api";
import { GoCircleSlash } from "react-icons/go";
import { BsFillCheckCircleFill } from "react-icons/bs";
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  FaIdCard,
  FaCar,
  FaUserAlt,
  FaLaptop,
  FaIdCardAlt,
} from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { showError } from "../../utils/helper";
import { MdSubtitles } from "react-icons/md";
import { selectUser } from "../../slices/user";
import { BACK_ID_NETWORKS } from "../../utils/constants";
import {
  StrapiAccessPointArray,
  StrapiAccessPointRelation,
} from "../../strapiModels/strapiAccessPoints";
import { StrapiPhotoTypesArray } from "../../strapiModels/photoTypes";

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface AdminDesktopEditAccessPointParams {
  accessServerId: StrapiAccessPointRelation | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCloseFunction: () => void;
}

const AdminDesktopEditAccessPoint = ({
  accessServerId,
  open,
  setOpen,
  onCloseFunction,
}: AdminDesktopEditAccessPointParams) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [accessPointName, setAccessPointName] = React.useState<string>("");
  const [idPhoto, setIdPhoto] = React.useState<boolean>(false);
  const [platesPhoto, setPlatesPhoto] = React.useState<boolean>(false);
  const [visitorPhoto, setVisitorPhoto] = React.useState<boolean>(false);
  const [equipmentPhoto, setEquipmentPhoto] = React.useState<boolean>(false);
  const [accreditationPhoto, setAccreditationPhoto] =
    React.useState<boolean>(false);
  const [lastAccessPointName, setLasAccessPointName] =
    React.useState<string>("");
  const [lastIdPhoto, setLastIdPhoto] = React.useState<boolean>(false);
  const [lastPlatesPhoto, setLastPlatesPhoto] = React.useState<boolean>(false);
  const [lastVisitorPhoto, setLastVisitorPhoto] =
    React.useState<boolean>(false);
  const [lastEquipmentPhoto, setLastEquipmentPhoto] =
    React.useState<boolean>(false);
  const [lastAccreditationPhoto, setLastAccreditationPhoto] =
    React.useState<boolean>(false);
  const [showPhotosContainer, setShowPhotosContainer] =
    React.useState<boolean>(false);
  const [photoList, setPhotolist] =
    React.useState<StrapiPhotoTypesArray | null>(null);

  const disableButton =
    accessPointName !== lastAccessPointName ||
    idPhoto !== lastIdPhoto ||
    platesPhoto !== lastPlatesPhoto ||
    visitorPhoto !== lastVisitorPhoto ||
    equipmentPhoto !== lastEquipmentPhoto ||
    accreditationPhoto !== lastAccreditationPhoto;

  const onChangeIdSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setIdPhoto(event.target.checked);
  };

  const onChangeCarSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setPlatesPhoto(event.target.checked);
  };

  const onChangeVisitorSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setVisitorPhoto(event.target.checked);
  };

  const onChangeEquipmentSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setEquipmentPhoto(event.target.checked);
  };
  const onChangeAccreditationSwitch = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setAccreditationPhoto(event.target.checked);
  };

  React.useEffect(() => {
    setShowPhotosContainer(false);
    if (accessServerId) {
      const idPhotoData =
        accessServerId?.data?.attributes?.photo_types?.data.find(
          (item) => item.attributes.name === "photo id"
        );
      const isIdPhotoFound = !!idPhotoData;
      const platesData =
        accessServerId?.data?.attributes?.photo_types?.data.find(
          (item) => item.attributes.name === "plates"
        );
      const platesFound = !!platesData;
      const visitorData =
        accessServerId?.data?.attributes?.photo_types?.data.find(
          (item) => item.attributes.name === "equipment"
        );
      const visitorFound = !!visitorData;
      const equipmentData =
        accessServerId?.data?.attributes?.photo_types?.data.find(
          (item) => item.attributes.name === "visitor"
        );
      const equipmentFound = !!equipmentData;
      setAccessPointName(accessServerId?.data?.attributes?.name);
      setLasAccessPointName(accessServerId?.data?.attributes?.name);
      setIdPhoto(isIdPhotoFound);
      setLastIdPhoto(isIdPhotoFound);
      setPlatesPhoto(platesFound);
      setLastPlatesPhoto(platesFound);
      setVisitorPhoto(visitorFound);
      setLastVisitorPhoto(visitorFound);
      setEquipmentPhoto(equipmentFound);
      setLastEquipmentPhoto(equipmentFound);
      // setAccreditationPhoto(
      //   accessServerId?.access_server?.settings?.accreditation
      // );
      // setLastAccreditationPhoto(
      //   accessServerId?.access_server?.settings?.accreditation
      // );
      setTimeout(() => {
        setShowPhotosContainer(true);
      }, 300);
    }
  }, [accessServerId]);

  const onUpdateAccessPoint = async () => {
    if (accessServerId?.data.id) {
      const photos: number[] = [];
      if (idPhoto) {
        photos.push(1);
      }
      if (platesPhoto) {
        photos.push(2);
      }
      if (visitorPhoto) {
        photos.push(3);
      }
      if (equipmentPhoto) {
        photos.push(4);
      }
      const payload: AccessPointPayload = {
        data: {
          name: accessPointName,
          photo_types: photos,
        },
      };
      try {
        setIsLoading(true);
        const response = await api.strapiUpdateAccessPointsByIdRequest(
          accessServerId?.data.id,
          payload
        );
        if (response) {
          setOpen(false);
          onCloseFunction();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const onUpdateAccessPoint = async () => {
  //   if (accessServerId?.access_server?.id) {
  //     const payload: UpdateAccessServerParams = {
  //       access_server: {
  //         name: accessPointName,
  //       },
  //       settings: {
  //         photo_id: idPhoto,
  //         other: equipmentPhoto,
  //         back_plates: visitorPhoto,
  //         front_plates: platesPhoto,
  //         accreditation: accreditationPhoto,
  //       },
  //     };
  //     try {
  //       setIsLoading(true);
  //       const response = await api.updateAccessServer(
  //         accessServerId?.access_server?.id,
  //         payload
  //       );
  //       if (response) {
  //         setOpen(false);
  //         onCloseFunction();
  //       }
  //     } catch (error: any) {
  //       console.error(error);
  //       showError(dispatch, labels.generic_error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  React.useEffect(() => {
    getPhotoType();
  }, []);

  const getPhotoType = async () => {
    try {
      setIsLoading(true);
      const response = await api.strapiPhotoTypesRequest();
      if (response) {
        setPhotolist(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: 600,
          height: `calc(100% - 99.5px)`,
          boxSizing: "border-box",
          position: "relative",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          }}
        >
          <p
            className="desktop-tables-font"
            style={{ fontSize: 18, marginLeft: 20, marginBottom: 25 }}
          >
            {labels.details}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 4,
              boxSizing: "border-box",
              padding: 30,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "30%", boxSizing: "border-box" }}>
                <p className="m-0 p-0 desktop-tables-font">
                  {labels.location}:
                </p>
              </div>
              <div style={{ width: "70%", boxSizing: "border-box" }}>
                <p className="m-0 p-0">
                  {accessServerId?.data?.attributes?.vanue_name}
                </p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                boxSizing: "border-box",
                marginTop: 20,
              }}
            >
              <div style={{ width: "30%", boxSizing: "border-box" }}>
                <p className="m-0 p-0 desktop-tables-font">{labels.address}:</p>
              </div>
              <div style={{ width: "70%", boxSizing: "border-box" }}>
                <p className="m-0 p-0">
                  {accessServerId?.data?.attributes?.address}
                </p>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 20 }}>
              <p className="m-0 p-0 desktop-tables-font">{labels.gates}:</p>
              <table
                style={{
                  border: "1px #ededed solid",
                  marginTop: 10,
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "35%",
                        boxSizing: "border-box",
                        padding: 6,
                        border: "1px #ededed solid",
                        backgroundColor: "#ededed",
                      }}
                    >
                      <p className="m-0 p-0" style={{ fontWeight: "normal" }}>
                        {labels.name}
                      </p>
                    </th>
                    <th
                      style={{
                        width: "15%",
                        boxSizing: "border-box",
                        padding: 6,
                        border: "1px #ededed solid",
                        backgroundColor: "#ededed",
                      }}
                    >
                      <p className="m-0 p-0" style={{ fontWeight: "normal" }}>
                        {labels.type}
                      </p>
                    </th>
                    {/* <Tooltip title={labels.label_text} arrow placement="top">
                      <th
                        style={{
                          width: "25%",
                          boxSizing: "border-box",
                          padding: 6,
                          border: "1px #ededed solid",
                          backgroundColor: "#ededed",
                        }}
                      >
                        <p className="m-0 p-0" style={{ fontWeight: "normal" }}>
                          {labels.label_printing}
                        </p>
                      </th>
                    </Tooltip>
                    <Tooltip
                      title={labels.pulse_text}
                      arrow
                      placement="top-end"
                    >
                      <th
                        style={{
                          width: "25%",
                          boxSizing: "border-box",
                          padding: 6,
                          border: "1px #ededed solid",
                          backgroundColor: "#ededed",
                        }}
                      >
                        <p className="m-0 p-0" style={{ fontWeight: "normal" }}>
                          {labels.remote_pulse}
                        </p>
                      </th>
                    </Tooltip> */}
                  </tr>
                </thead>
                <tbody>
                  {accessServerId?.data?.attributes?.gates?.data.map((item) => (
                    <tr key={item?.id}>
                      <td
                        style={{
                          width: "35%",
                          boxSizing: "border-box",
                          padding: 6,
                          border: "1px #ededed solid",
                        }}
                      >
                        <p className="m-0 p-0 desktop-tables-font">
                          {item?.attributes?.name}
                        </p>
                      </td>
                      <td
                        style={{
                          width: "15%",
                          boxSizing: "border-box",
                          padding: 6,
                          border: "1px #ededed solid",
                        }}
                      >
                        <p
                          className="m-0 p-0 desktop-tables-font"
                          style={{ textAlign: "center" }}
                        >
                          {item?.attributes?.type === "entrance"
                            ? labels.access
                            : labels.exit}
                        </p>
                      </td>
                      {/* <td
                          style={{
                            width: "25%",
                            boxSizing: "border-box",
                            padding: 6,
                            border: "1px #ededed solid",
                            textAlign: "center",
                          }}
                        >
                          {data_print_response ? (
                            <BsFillCheckCircleFill
                              fontSize={20}
                              color="#458747"
                            />
                          ) : (
                            <GoCircleSlash fontSize={20} color="#b94a48" />
                          )}
                        </td>
                        <td
                          style={{
                            width: "25%",
                            boxSizing: "border-box",
                            padding: 6,
                            border: "1px #ededed solid",
                            textAlign: "center",
                          }}
                        >
                          {remote_pulse ? (
                            <BsFillCheckCircleFill
                              fontSize={20}
                              color="#458747"
                            />
                          ) : (
                            <GoCircleSlash fontSize={20} color="#b94a48" />
                          )}
                        </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <p
            className="desktop-tables-font"
            style={{
              fontSize: 18,
              marginLeft: 20,
              marginBottom: 25,
              marginTop: 30,
            }}
          >
            {labels.settings}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 4,
              boxSizing: "border-box",
              padding: 30,
              marginBottom: 40,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="m-0 p-0 mb-4 desktop-tables-font"
                style={{ padding: 0 }}
              >
                Nombre del punto de acceso
              </p>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="outlined"
                  placeholder={`Nombre`}
                  type="text"
                  value={accessPointName}
                  onChange={(e) => {
                    setAccessPointName(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            {showPhotosContainer ? (
              <div
                style={{
                  width: "100%",
                }}
              >
                <p
                  className="m-0 p-0 mb-4 mt-8 desktop-tables-font"
                  style={{ padding: 0 }}
                >
                  Fotografías requeridas
                </p>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 4,
                    padding: "8px 14px",
                    border: "1px #ededed solid",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaIdCard fontSize={22} />
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      {user?.network.id !== BACK_ID_NETWORKS
                        ? labels.transactions.id
                        : labels.id_front}
                    </p>
                  </div>
                  <TemporarySwitch
                    defaultChecked={idPhoto}
                    onChange={onChangeIdSwitch}
                  />
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 4,
                    padding: "8px 14px",
                    border: "1px #ededed solid",
                    marginTop: 8,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaCar fontSize={22} />
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      {labels.plates}
                    </p>
                  </div>
                  <TemporarySwitch
                    defaultChecked={platesPhoto}
                    onChange={onChangeCarSwitch}
                  />
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 4,
                    padding: "8px 14px",
                    border: "1px #ededed solid",
                    marginTop: 8,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaUserAlt fontSize={22} />
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      {labels.visitor}
                    </p>
                  </div>
                  <TemporarySwitch
                    defaultChecked={visitorPhoto}
                    onChange={onChangeVisitorSwitch}
                  />
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 4,
                    padding: "8px 14px",
                    border: "1px #ededed solid",
                    marginTop: 8,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaLaptop fontSize={22} />
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      {labels.equipment}
                    </p>
                  </div>
                  <TemporarySwitch
                    defaultChecked={equipmentPhoto}
                    onChange={onChangeEquipmentSwitch}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: 336.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "flex-end",
                // padding: 40,
                paddingTop: 40,
                // paddingBottom: 40,
                // marginBottom: 40,
              }}
            >
              <Button
                // fullWidth
                variant="contained"
                size="large"
                style={{ height: 56, width: 400 }}
                disabled={!disableButton}
                onClick={onUpdateAccessPoint}
              >
                {labels.save_changes}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDesktopEditAccessPoint;
