// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-guest-button-style {
  color: white !important;
  text-transform: none !important;
  font-weight: lighter !important;
}

.background-guest-div {
  background: #f2f2f2;
  border-radius: 5px;
}

.create-event-button {
  font-weight: lighter !important;
}`, "",{"version":3,"sources":["webpack://./src/routes/NewEvent/new-event.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+BAAA;EACA,+BAAA;AACF;;AAEA;EACI,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,+BAAA;AACJ","sourcesContent":[".add-guest-button-style {\n  color: white !important;\n  text-transform: none !important;\n  font-weight: lighter !important;\n}\n\n.background-guest-div {\n    background: #f2f2f2;\n    border-radius: 5px;\n  }\n\n.create-event-button {\n    font-weight: lighter !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
