// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credential {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: 1em;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.show-content {
  width: 100%;
  max-height: 0px;
  transition: max-height 0.3s ease-in-out;
}

.show-content.expanded {
  max-height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/components/TransactionListContainer/transaction-list-container.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,yHAAA;AACF;;AAGA;EACE,WAAA;EACA,eAAA;EACA,uCAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".credential {\n  width: 100%;\n  box-sizing: border-box;\n  cursor: pointer;\n  background-color: #fff;\n  border-radius: 4px;\n  padding: 1em;\n  overflow: hidden;\n  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),\n    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);\n}\n\n.show-content {\n  width: 100%;\n  max-height: 0px;\n  transition: max-height 0.3s ease-in-out;\n}\n\n.show-content.expanded {\n  max-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
