// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.days-rows-1-style {
  background: #eeeeee;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.days-rows-2-style {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  background: var(--main-white-color);
}

.days-table-general-style {
  width: auto;
  border-radius: 5px;
  border-collapse: collapse;
}

.check-style {
  margin-left: 20px;
  margin-right: 20px;
}

.days-container {
  background: #eeeeee;
  border-radius: 25px;
  height: 50px;
}

.single-day-container-on {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background-color: var(--main-primary-color);
  border: var(--main-primary-color) solid 1px;
}

.single-day-container-off {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: var(--main-primary-color) solid 1px;
}`, "",{"version":3,"sources":["webpack://./src/routes/NewInvitation/new-invitation.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,2CAAA;AACF;;AAEA;EACE,2CAAA;EACA,mCAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EAEA,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,2CAAA;EACA,2CAAA;AAAF;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,2CAAA;AAAF","sourcesContent":[".days-rows-1-style {\n  background: #eeeeee;\n  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;\n}\n\n.days-rows-2-style {\n  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;\n  background: var(--main-white-color);\n}\n\n.days-table-general-style {\n  width: auto;\n  border-radius: 5px;\n  border-collapse: collapse;\n}\n\n.check-style {\n  margin-left: 20px;\n  margin-right: 20px;\n}\n\n.days-container {\n  background: #eeeeee;\n  // border: 1px var(--main-disabled-color) solid;\n  border-radius: 25px;\n  height: 50px;\n}\n\n.single-day-container-on {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 30px;\n  width: 30px;\n  border-radius: 20px;\n  background-color: var(--main-primary-color);\n  border: var(--main-primary-color) solid 1px;\n}\n\n.single-day-container-off {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 30px;\n  width: 30px;\n  border-radius: 20px;\n  border: var(--main-primary-color) solid 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
