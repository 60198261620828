import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { StrapiPropertyData } from "../../strapiModels/strapiProperty";

export interface StrapiPropertyState {
  property: StrapiPropertyData | null;
}

const initialState: StrapiPropertyState = {
  property: null,
};

export const strapiPropertySlice = createSlice({
  name: "strapi_property",
  initialState,
  reducers: {
    getStrapiProperty: (state) => {
      const newState = { ...state };
      const currentProperty = localStorage.getItem("strapi_property");
      if (currentProperty) {
        newState.property = JSON.parse(currentProperty);
      }
      return newState;
    },
    setStrapiProperty: (state, action) => {
      const newState = { ...state };
      newState.property = action.payload;
      // remove when user info endpoint available
      localStorage.setItem("strapi_property", JSON.stringify(action.payload));
      return newState;
    },
    resetStrapiProperty: (state) => {
      const newState = { ...state };
      newState.property = null;
      localStorage.removeItem("strapi_property");
      return newState;
    },
  },
});

export const { setStrapiProperty, getStrapiProperty, resetStrapiProperty } =
  strapiPropertySlice.actions;

export const selectStrapiProperty = (state: RootState) =>
  state?.strapiProperty?.property;

export default strapiPropertySlice.reducer;
