import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { StrapiUnitData } from "../../strapiModels/strapiUnit";

export interface StrapiUnitState {
  unit: StrapiUnitData | null;
}

const initialState: StrapiUnitState = {
  unit: null,
};

export const strapiUnitSlice = createSlice({
  name: "strapi_unit",
  initialState,
  reducers: {
    getStrapiUnit: (state) => {
      const newState = { ...state };
      const currentUnit = localStorage.getItem("strapi_unit");
      if (currentUnit) {
        newState.unit = JSON.parse(currentUnit);
      }
      return newState;
    },
    setStrapiUnit: (state, action) => {
      const newState = { ...state };
      newState.unit = action.payload;
      // remove when user info endpoint available
      localStorage.setItem("strapi_unit", JSON.stringify(action.payload));
      return newState;
    },
    resetStrapiUnit: (state) => {
      const newState = { ...state };
      newState.unit = null;
      localStorage.removeItem("strapi_unit");
      return newState;
    },
  },
});

export const { setStrapiUnit, getStrapiUnit, resetStrapiUnit } =
  strapiUnitSlice.actions;

export const selectStrapiUnit = (state: RootState) => state?.strapiUnit?.unit;

export default strapiUnitSlice.reducer;
