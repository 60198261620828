// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 60px;
  z-index: 99;
  position: fixed;
  bottom: 70px;
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px #d9d9d9 solid;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonBottom/button-bottom.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;AACF","sourcesContent":[".button-container {\n  background-color: rgba(255, 255, 255, 0.8);\n  width: 100vw;\n  height: 60px;\n  z-index: 99;\n  position: fixed;\n  bottom: 70px;\n  margin: 0px !important;\n  padding: 0px !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-top: 1px #d9d9d9 solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
