import axios from "axios";
import moment from "moment";
import { StrapiUser, StrapiUserPayload } from "../strapiModels/strapiUser";
import { StrapiUnit, StrapiUnitArray } from "../strapiModels/strapiUnit";
import { StrapiProperty } from "../strapiModels/strapiProperty";
import {
  StrapiAccessPointArray,
  StrapiAccessPointRelation,
} from "../strapiModels/strapiAccessPoints";
import { StrapiPhotoTypesArray } from "../strapiModels/photoTypes";
import { StrapiOrganizationalAreasArray } from "../strapiModels/organizationalAreas";
import {
  StrpiAccessKey,
  StrpiAccessKeyArray,
  StrpiAccessKeyRelation,
} from "../strapiModels/accessKey";
import { StrapiOneTimeVisitorRelation } from "../strapiModels/oneTimeVisitor";

export const instance = axios.create({
  baseURL: "https://app.passtrack.mx/",
  timeout: 30000,
});

export const instance_strapi = axios.create({
  baseURL: "https://strapi.passtrack.mx",
  timeout: 30000,
});

export const strapi_token =
  "0d63c17b268e07463121ecde59ed9de474e0b5008c2c67e036ff1b24ef1116c8c3249d89d99ca3fa621d457073bac0c75ccd3819c8a9a87d710b3d41896f6c110829812cf3066e7c1365fa44f18bd754f3b54ad2ce39f12edd49a116fea5221e34ed2643e63cf5f702697806f825b3f8ff0bfa9f2722fc9fc416dc7ba765cabe";
//-------------------------------------------------------

//End point (General): login - POST

export interface LoginRequestParams {
  email: string;
  password: string;
}

const loginRequest = async (params: LoginRequestParams) => {
  const { data }: { data: LoginResponse } = await instance.post(
    "/api/v1/login",
    params
  );
  return data;
};

interface LoginResponse {
  meta: Meta;
  user: User;
}

interface Meta {
  auth_token: string;
}

export interface User {
  id: number;
  access_servers: AccessServer[];
  email: string;
  last_access_selected: LastAccessSelected | null;
  name: string;
  deactivated_at: string | null;
  network: Network;
  network_group: NetworkGroup;
  phone: string;
  roles: Role[];
  email_notification: boolean;
  push_notification: boolean;
}

interface NetworkGroup {
  id: number;
  network_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface AccessServer {
  id: number;
  name: string;
  settings: AccessServerSettings;
  venue_name: string;
}

interface AccessServerSettings {
  back_plates: boolean;
  front_plates: boolean;
  photo_id: boolean;
  other: boolean;
  accreditation: boolean;
}

interface LastAccessSelected {
  gates: LastAccessSelectedGates[];
  id: number;
  name: string;
  settings: LastAccessSelectedSettings;
  venue_name: string;
}

export interface LastAccessSelectedGates {
  access_server_id: number;
  id: number;
  name: string;
  remote_pulse: boolean;
  type: string;
}

interface LastAccessSelectedSettings {
  back_plates: boolean;
  front_plates: boolean;
  photo_id: boolean;
  other: boolean;
  accreditation: boolean;
}

interface Network {
  company_name: string;
  country_id: number;
  id: number;
  license: NetworkLicense;
  logo_url: null | string;
  passback_activated_by_default: boolean;
  block_secondary_user: boolean;
  block_antipassback: boolean;
}

interface NetworkLicense {
  deleted_at: null | string;
  end_date: string;
  license_type_id: number;
  max_hosts: number;
  max_network_admins: number;
  max_secondary_users_per_group: number;
  start_date: string;
}

interface Role {
  created_at: string;
  deleted_at: null | string;
  id: number;
  name: string;
  resource_id: number;
  resource_type: string;
  update_at: string;
}
//-------------------------------------------------------

//End point (Transactions): transactions - GET

const logbookRequest = async (page: number, date: string | null) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/transactions?page=${page}${
      date ? `&date=${moment(date).format("YYYY-MM-DD")}` : ""
    }`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
//-------------------------------------------------------

//End point (Transactions): transactions - GET - two

export interface GetLogbookRegister {
  transactions: GetLogbookRegisterTransaction[];
  page: number;
  results: number;
  total_pages: number;
}

export interface GetLogbookRegisterTransaction {
  created_at: string;
  access_granted: boolean;
  access_allowed: boolean;
  notes: string | null;
  access_type: string;
  id: number;
  event_hour: string;
  visitor_name: string;
  access_key_type: string;
  user: TransactionUser;
  event: number | null;
  access_server: TransactionAccessServer;
  access_key_status: TransactionAccessKeyStatus;
  access_key_id: number | null;
  access_status_id: number;
  external_key_id: number | null;
  related_transaction_id: number | null;
  transaction_photos: TransactionPhotos[];
  created_by_user: TransactionCreatedBy;
  custom_attrs: {};
}

interface TransactionUser {
  name: string;
  group: string;
  email: string;
  phone: string;
}

interface TransactionAccessServer {
  name: string;
  venue_name: string;
  id: number;
}

interface TransactionAccessKeyStatus {
  description: string;
}

export interface TransactionPhotos {
  access_server_photo_type: string;
  url: string;
}

interface TransactionCreatedBy {
  name: string;
  group: string;
  email: string;
  phone: string;
}

const logbookRequestTwo = async (
  page: number,
  results: number,
  date: string,
  access_server: number | string,
  query: string,
  filter: string | null
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/transactions?page=${page}&results=${results}&date=${date}&access_server=${access_server}&query=${query}&filter=${filter}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as GetLogbookRegister;
};

//-------------------------------------------------------

//End point (Access Keys): access_keys - GET

export interface GetAccessKeys {
  access_keys: GetAccessKeysAccessKeys[];
  results: number;
  page: number;
  total_pages: number;
}

export interface GetAccessKeysAccessKeys {
  id: number;
  unique_id: string;
  visitor_name: string;
  visitor_phone: string;
  visitor_email: string;
  deleted_at: null | string;
  last_transaction_type: null | string;
  access_key_type_id: number;
  valid_until_date: string;
  valid_from_date: string;
  valid_from: string;
  valid_until: string;
  user: GetAccessKeysUser;
  access_servers: GetAccessKeysAccessServer[];
  time_zone_name: string;
}

interface GetAccessKeysUser {
  name: string | null;
  network_group: GetAccessKeysUserNetwork;
}

interface GetAccessKeysUserNetwork {
  name: string | null;
}

interface GetAccessKeysAccessServer {
  name: string | null;
}

const invitationsRequest = async (
  page: number,
  results: number,
  query: string,
  date: string | null,
  filter: string | null,
  access_key_type: string | null
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/access_keys?page=${page}&results=${results}&query=${query}&date=${date}&filter=${filter}&access_key_type=${access_key_type}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as GetAccessKeys;
};

//-------------------------------------------------------

//End point (Community Members): community members index - GET

export interface GetCommunityMembers {
  community_members: GetCommunityMember[];
  page: string;
  results: string;
  total_pages: number;
}

export interface GetCommunityMember {
  id: number;
  email: string;
  name: string;
  phone: string;
  user: GetCommunityMemberUser;
}

interface GetCommunityMemberUser {
  name: string;
  email: string;
  id: number;
  phone: string;
  network_group: GetCommunityMemberUserNetworkGroup;
}

interface GetCommunityMemberUserNetworkGroup {
  name: string;
}

const communityMembersRequest = async (
  page: number,
  results: number,
  query: string
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/community_members?page=${page}&results=${results}&query=${query}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as GetCommunityMembers;
};

//-------------------------------------------------------

//End point (Transactions): transactions - POST

export interface RegisterVisitRequestParams {
  visitor_name?: string;
  reason_for_visit?: string;
  notes?: string;
  user_id?: number;
  gate_id: number;
  access_key_type_id?: number;
  key?: string;
  access_allowed?: boolean;
  custom_attrs?: {};
  photos?: {
    id?: string;
    other?: string;
    back_plates?: string;
    front_plates?: string;
  };
}

export interface RegisterVisitResponseParams {
  transaction: TransactionParams;
  print_data?: PrintDataParams;
}

interface TransactionParams {
  created_at: string;
  access_granted: boolean;
  access_allowed: boolean;
  access_type: string;
  id: number;
  event_hour: string;
  visitor_name: string;
  access_key_type: string;
  user: UserTransactionParams;
  event: number;
  access_server: AccessServerTransactionParams;
  access_key_status: AccessKeyStatusTransactionParams;
  access_key_id: number;
  external_key: number;
  related_transaction_id: number;
  transaction_photos: TransactionPhotosTransactionParams[];
  custom_attrs: {};
}

interface UserTransactionParams {
  name: string;
  group: GroupUserTransactionParams;
}

interface GroupUserTransactionParams {
  name: string;
}

interface AccessServerTransactionParams {
  name: string;
  venue_name: string;
}

interface AccessKeyStatusTransactionParams {
  description: string;
}

interface TransactionPhotosTransactionParams {
  access_server_photo_type: string;
  url: string;
}

interface PrintDataParams {
  visitor_name: string;
  // visitor_last_name: string;
  created_at: string;
  user_name: string;
  user_network_group: string;
  photo_url: string;
  venue: string;
}

const registerVisitRequest = async (params: RegisterVisitRequestParams) => {
  const { data }: any = await instance.post("/api/v1/transactions", params, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as RegisterVisitResponseParams;
};
//-------------------------------------------------------

//End point (Users): users/list - GET

export interface UsersList {
  users: UserDetails[];
}

export interface UserDetails {
  id: number;
  name: string;
  email: string;
  network_group: Department;
  phone: string;
}

export interface Department {
  name: string;
}

const usersListRequest = async (q: string) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/users/list?q=${q}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as UsersList;
};
//-------------------------------------------------------

//End point (Access Keys): access_keys/validate_keys/sin transaccion - GET

export interface ValidateKeyResponse {
  key_data: KeyData;
  requirements: Requirements;
  access_granted: boolean;
}

export interface KeyData {
  visitor_name: string;
  access_time: string;
  valid_from: string;
  valid_until: string;
  reason_for_visit: string;
  type: string;
  user: KeyDataUser;
  access_servers: KeyDataAccessServers[];
  access_key_status: KeyDataAccessKeyStatus;
  key_restriction: KeyDataKeyRestrictions | null;
}

export interface KeyDataUser {
  name: string;
  group: string;
  email: string;
  phone: string;
}

export interface KeyDataAccessServers {
  name: string;
  venue_name: string;
  id: number;
}

export interface KeyDataAccessKeyStatus {
  message: string;
  class_name: string;
  message_description: string;
}

export interface Requirements {
  photo_id: boolean;
  back_plates: boolean;
  front_plates: boolean;
  other: boolean;
  accreditation: boolean;
}

export interface KeyDataKeyRestrictions {
  access_key_id: number;
  days: ["0" | "1" | "2" | "3" | "4" | "5" | "6"];
  duration_in_minutes: number;
  id: number;
  time: string;
}

const validateKeyRequestNoTransaction = async (
  gateId: number,
  qrKey: string
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/access_keys/validate?gate=${gateId}&key=${qrKey}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as ValidateKeyResponse;
};
//-------------------------------------------------------

//End point (Users): control_access_server - POST

export interface ChangeAccessServerParams {
  access_server: number;
}

const changeAccessServerRequest = async (params: ChangeAccessServerParams) => {
  const { data }: any = await instance.post(
    "/api/v1/users/control_access_server",
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data.user as User;
};

//-------------------------------------------------------

//End point (Transactions): log_exit - POST

export interface LogExitRegisterParams {
  transaction_id: number;
  gate_id: number;
}

const logExitRegisterRequest = async (params: LogExitRegisterParams) => {
  const { data }: any = await instance.post(
    "/api/v1/transactions/log_exit",
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as LogExitRegisterParams;
};

//-------------------------------------------------------

//End point (Transactions): access_keys/add_photos - POST

export interface AccessKeysAddPhotosParams {
  transaction: number;
  photos?: {
    id?: string;
    other?: string;
    back_plates?: string;
    front_plates?: string;
  };
}

const accessKeysAddPhotosRequest = async (
  params: AccessKeysAddPhotosParams
) => {
  const { data }: any = await instance.post(
    "/api/v1/access_keys/add_photos",
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as AccessKeysAddPhotosParams;
};

//-------------------------------------------------------

//End point (Transactions): transactions/{id} - GET

const transactionIdRequest = async (id: number) => {
  const { data }: any = await instance.get(`/api/v1/transactions/${id}`, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data.transaction as GetLogbookRegisterTransaction;
};

//-------------------------------------------------------

//End point (Access Keys): id - GET

export interface AccessKey {
  id: number;
  visitor_name: string;
  access_key_type_id: number;
  visitor_email: string;
  visitor_phone: string;
  valid_until: string;
  valid_from: string;
  reason_for_visit: string;
  deleted_at: string | null;
  unique_id: string;
  last_transaction_type: string | null;
  access_servers: AccessServers[];
  key_restriction: KeyRestriction;
  custom_attrs: {};
  user: AccessKeyUser;
}

interface AccessServers {
  name: string;
  venue_name: string;
  id: number;
  settings: AccessServerSettings;
  time_zone: string;
  time_zone_offset: string;
  time_zone_name: string;
}

interface AccessServerSettings {
  photo_id: boolean;
  back_plates: boolean;
  front_plates: boolean;
  other: boolean;
  accreditation: boolean;
}

interface KeyRestriction {
  id: number;
  access_key_id: number;
  duration_in_minutes: number;
  time: string;
  days: ["0" | "1" | "2" | "3" | "4" | "5" | "6"];
}

interface AccessKeyUser {
  name: string;
  group: string;
  email: string;
  phone: string;
}

const invitationIdRequest = async (id: number) => {
  const { data }: any = await instance.get(`/api/v1/access_keys/${id}`, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data.access_key as AccessKey;
};

//-------------------------------------------------------

//End point (Access Keys): access_keys (create access key) - POST

export interface CreateAccessKeyParams {
  access_key: CreateAccessKey;
}

export interface CreateAccessKey {
  visitor_name: string;
  visitor_phone: string;
  visitor_email: string;
  country_code: string;
  access_key_type_id: number;
  reason_for_visit: string;
  notes: string;
  visitor_duration: string;
  duration: string;
  temporary_duration: string;
  valid_from: string;
  community_member_id: string | null;
  access_servers: number[];
  save_community_member: boolean;
  passback_tracking?: boolean;
  provider?: string;
  key_restrictions?: CreateAccessKeyRestrictions;
  custom_attrs?: {};
}

interface CreateAccessKeyRestrictions {
  days: number[] | null;
  time: string | null;
  duration_in_minutes: number | null;
}

interface CreateAccessKeyResponse {
  access_key: AccessKey;
}

const createAccessKey = async (params: CreateAccessKeyParams) => {
  const { data }: any = await instance.post("/api/v1/access_keys", params, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as CreateAccessKeyResponse;
};

//-------------------------------------------------------

//End point (Community Members): community_members/list - GET

export interface CommunityMembersList {
  community_members: CommunityMembers[];
}

export interface CommunityMembers {
  id: number;
  email: string;
  name: string;
  phone: string;
  user: CommunityMemberUser;
}

interface CommunityMemberUser {
  email: string;
  id: number;
  name: string;
  phone: string;
  network_group: CommunityMemberUserNetworkGroup | null;
}

interface CommunityMemberUserNetworkGroup {
  name: string;
}

const getCommunityMembersList = async (query: string) => {
  const { data }: any = await instance.get(
    `/api/v1/community_members/list?key=${query}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as CommunityMembersList;
};

//-------------------------------------------------------

//End point (Users): users/network - GET

export interface UsersByDepartment {
  users: Users[];
  page: number;
  results: number;
  total_pages: number;
}

export interface Users {
  id: number;
  name: string;
  email: string;
  phone: string;
  network_group: string;
  access_servers: UsersAccessServers[];
  roles: UsersRoles[];
  invited_by: UsersInvitedBy;
}

export interface UsersAccessServers {
  id: number;
  name: string;
  venue_name: string;
  settings: UsersSettings;
}

interface UsersSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

interface UsersRoles {
  id: number;
  name: string;
  resource_type: string;
  resource_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface UsersInvitedBy {
  name: string;
  email: string;
  id: number;
  phone: string;
  network_group: string | null;
}

const usersByNetworkRequest = async (
  page: number,
  results: number,
  query: string
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/users?page=${page}&results=${results}&query=${query}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as UsersByDepartment;
};

//-------------------------------------------------------

//End point (User): users (create User) - POST

export interface CreateUserParams {
  name: string;
  email: string;
  phone?: string;
  country_code: string;
  role: string;
  network_group?: number;
  access_servers?: number[];
}

const createUser = async (params: CreateUserParams) => {
  const { data }: any = await instance.post("/api/v1/users", params, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as CreateUserParams;
};

//-------------------------------------------------------

//End point (User): (delete user) - DELETE

const deleteUser = async (deleteUserId: number) => {
  const { data }: any = await instance.delete(
    `https://app.passtrack.mx/api/v1/users/${deleteUserId}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

//-------------------------------------------------------

//End point (User): (update user) - PATCH

export interface EditUserParams {
  user: {
    name?: string;
    email?: string;
    phone?: string;
    email_notification?: boolean;
    push_notification?: boolean;
  };
}

const editUser = async (id: number, params: EditUserParams) => {
  const { data }: any = await instance.patch(
    `https://app.passtrack.mx/api/v1/users/${id}`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EditUserParams;
};

//-------------------------------------------------------

//End point (AccessKey): (delete access key) - DELETE

const deleteAccessKey = async (deleteAccessKeyId: number) => {
  const { data }: any = await instance.delete(
    `https://app.passtrack.mx/api/v1/access_keys/${deleteAccessKeyId}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

//-------------------------------------------------------

//End point (Events): list of events - GET

export interface EventsList {
  events: EventsListEvents[];
  page: number;
  results: number;
  total_pages: number;
}

export interface EventsListEvents {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  event_type_id: number;
  tolerance: number;
  number_of_guests: number;
  access_servers: EventsAccessServers[];
}

interface EventsAccessServers {
  name: string;
  venue_name: string;
  id: number;
  settings: EventsAccessServersSettings;
  time_zone: string;
  time_zone_offset: string;
  time_zone_name: string;
}

interface EventsAccessServersSettings {
  other: boolean;
  photo_id: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

const eventListRequest = async (
  page: number,
  results: number,
  user: number | string,
  eventName: string,
  access_server: number | string
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/events?page=${page}&results=${results}&user=${user}&name=${eventName}&access_server=${access_server}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EventsList;
};

//-------------------------------------------------------

//End point (Users): password (reset password) - POST

export interface ResetPasswordParams {
  email: string;
}

const resetPassword = async (params: ResetPasswordParams) => {
  const { data }: any = await instance.post(
    "/api/v1/users/reset_password",
    params,
    {}
  );
  return data as ResetPasswordParams;
};

//-------------------------------------------------------

//End point (Events): events (create event) - POST

export interface CreateEventParams {
  event: CreateEvent;
  guests: EventGuests[];
  access_servers: number[];
}

interface CreateEvent {
  name: string;
  description: string;
  start_date: string;
  tolerance: number;
  event_type_id: number;
  custom_attrs?: {};
}

export interface EventGuests {
  id?: number;
  community_member?: boolean;
  name?: string;
  phone?: string;
  email?: string;
  save?: boolean;
}

const createEvent = async (params: CreateEventParams) => {
  const { data }: any = await instance.post("/api/v1/events", params, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as CreateEventParams;
};

//-------------------------------------------------------

//End point (Providers): providers - GET

export interface Providers {
  providers: ProvidersProvider[];
}
export interface ProvidersProvider {
  name: string;
  display_name: string;
}

const providersRequest = async () => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/providers`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as Providers;
};
//-------------------------------------------------------

//End point (Events): Event access-keys - GET

export interface EventGuestList {
  access_keys: EventAccessKeys[];
  page: number;
  results: number;
  total_pages: number;
}
export interface EventAccessKeys {
  id: number;
  unique_id: string;
  visitor_name: string;
  visitor_phone: string;
  visitor_email: string;
  deleted_at: string;
  last_transaction_type: string;
  access_key_type_id: string;
  valid_until_date: string;
  valid_from_date: string;
  user: EventAccessKeysUser;
  access_servers: EventAccessKeyAccessServer[];
}

interface EventAccessKeysUser {
  name: string;
  network_group: EventAccessKeysUserNetwork;
}

interface EventAccessKeysUserNetwork {
  name: string;
}

interface EventAccessKeyAccessServer {
  name: string;
}

const eventAccessKeysRequest = async (
  id: number,
  query: string,
  page: number,
  results: number
) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/events/${id}/access_keys?query=${query}&page=${page}&results=${results}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EventGuestList;
};
//-------------------------------------------------------

//End point (Access Key): (update visitor invitation) - PATCH

export interface EditVisitInvitationParams {
  access_key: EditVisitInvitationAccessKeyParams;
}
export interface EditVisitInvitationAccessKeyParams {
  visitor_name: string;
  visitor_email: string;
  country_code: string;
  visitor_phone: string;
  reason_for_visit: string;
  notes: string;
  visitor_duration: string;
  valid_from: string;
  community_member_id: string | null;
  access_servers: number[];
  save_community_member: boolean;
  passback_tracking: boolean;
}

const editVisitInvitation = async (
  id: number,
  params: EditVisitInvitationParams
) => {
  const { data }: any = await instance.patch(
    `https://app.passtrack.mx/api/v1/access_keys/${id}`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EditVisitInvitationParams;
};

//-------------------------------------------------------

//End point (Event): (update event) - PATCH
export interface EditEventParams {
  event: EditEventEventParams;
  access_servers: number[];
}
interface EditEventEventParams {
  name: string;
  description: string;
  start_date: string;
  tolerance: number;
}

const editEvent = async (id: number, params: EditEventParams) => {
  const { data }: any = await instance.patch(
    `https://app.passtrack.mx/api/v1/events/${id}`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EditEventParams;
};

//-------------------------------------------------------

//End point (Events): Event Id - GET

export interface EventIdParams {
  event: EventIdEventParams;
}
export interface EventIdEventParams {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  tolerance: number;
  event_type_id: number;
  number_of_guests: number;
  access_servers: EventIdAccessServers[];
}
interface EventIdAccessServers {
  id: number;
  name: string;
  venue_name: string;
  settings: EventIdAccessServersSettings;
  time_zone: string;
  time_zone_offset: string;
  time_zone_name: string;
}
interface EventIdAccessServersSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

const getEventId = async (id: number) => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/events/${id}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EventIdParams;
};
//-------------------------------------------------------

//End point (User dynamic key): User Dynamic key - GET

export interface UserKey {
  access_key: UserKeyAccessKey;
}

interface UserKeyAccessKey {
  id: number;
  visitor_name: string;
  visitor_phone: string;
  visitor_email: string;
  access_key_type_id: string;
  reason_for_visit: string;
  valid_from: string;
  valid_until: string;
  deleted_at: string;
  unique_id: string;
  last_transaction_type: string;
  access_servers: UserKeyAccessServers[];
  key_restrictions: UserKeyKeyRestrictions;
  user: UserKeyUser;
}

interface UserKeyUser {
  name: string;
  email: string;
  phone: string;
  group: string;
}

interface UserKeyKeyRestrictions {
  days: number[];
  time: string;
  duration_in_minutes: number;
}

interface UserKeyAccessServers {
  id: number;
  name: string;
  venue_name: string;
  settings: UserKeyAccessServersSettings;
}

interface UserKeyAccessServersSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

const getDynamicKey = async () => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/access_keys/user_access_key`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as UserKey;
};
//-------------------------------------------------------

//End point (Events): add_guest - POST

export interface AddEventGuestsParams {
  guests: AddEventGuestEvent[];
}

export interface AddEventGuestEvent {
  id?: number;
  community_member: boolean;
  phone: string;
  name: string;
  email: string;
  save: boolean;
}

const addEventGuestRequest = async (
  id: number,
  params: AddEventGuestsParams
) => {
  const { data }: any = await instance.post(
    `/api/v1/events/${id}/add_guests`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as EventIdParams;
};

//-------------------------------------------------------

//End point (Access keys): get custom attributes - GET

export interface AccessKeyCustomAttributes {
  custom_attributes: CustomAttributes[];
}
export interface CustomAttributes {
  attribute_name: string;
  attribute_type: string;
  options: any[] | null;
  required: boolean;
}

const getInvitationCustomAttributes = async () => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/access_keys/custom_attributes`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as AccessKeyCustomAttributes;
};
//-------------------------------------------------------

//End point (Register): get custom attributes - GET

export interface RegisterCustomAttributes {
  custom_attributes: CustomAttributesRegister[];
}
export interface CustomAttributesRegister {
  attribute_name: string;
  attribute_type: string;
  required: boolean;
}

const getRegisterCustomAttributes = async () => {
  const { data }: any = await axios.get(
    `https://app.passtrack.mx/api/v1/transactions/custom_attributes`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as RegisterCustomAttributes;
};
//-------------------------------------------------------

//End point (Access servers): Get the current guests inside an access server - GET

export interface getGuestsInsideAccessServerParams {
  guests: number;
}

const getGuestsInsideAccessServer = async (id: number) => {
  const { data }: any = await instance.get(
    `/api/v1/access_servers/${id}/current_guests`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as getGuestsInsideAccessServerParams;
};
//-------------------------------------------------------

//End point (Events): add_guest - POST

export interface OpenGateParams {
  id: number;
}

const openGate = async (params: OpenGateParams) => {
  const { data }: any = await instance.post(
    `/api/v1/transactions/open_gate`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

//-------------------------------------------------------

//End point (Network groups): Get network groups - GET

export interface NetworkGroupsParams {
  network_groups: NetworkGroups[];
  page: number;
  results: number;
  total_pages: number;
}

export interface NetworkGroups {
  id: number;
  network_id: number;
  name: string;
  access_servers: NGAccessServers[];
  users: NGUsers[];
}

export interface NGAccessServers {
  id: number;
  name: string;
  venue_name: string;
  time_zone: string;
  time_zone_offset: string;
  time_zone_name: string;
  settings: NGASSettings;
}

interface NGUsers {
  id: number;
  name: string;
  email: string;
  network_group: NGUNetworkGroup;
}

interface NGASSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

interface NGUNetworkGroup {
  name: string;
}

const getNetworkGroups = async (
  query: string | null,
  access_server: number | string | null,
  page: number,
  results: number
) => {
  const { data }: any = await instance.get(
    `/api/v1/network_groups?page=${page}&results=${results}${
      query ? `&query=${query}` : ""
    }${access_server ? `&access_server=${access_server}` : ""}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as NetworkGroupsParams;
};
//-------------------------------------------------------

//End point (Network groups): Get network groups - GET

export interface AccessServersResponse {
  access_servers: AccessServersRes[];
  page: number;
  results: number;
  total_pages: number;
}

export interface AccessServersRes {
  id: number;
  name: string;
  address: string;
  longitude: string;
  latitude: string;
  time_zone: string;
  time_zone_offset: string;
  time_zone_name: string;
  settings: AccessServerSettings;
  venue: AccessServerVenue;
  gates: AccessServerGates[];
}

interface AccessServerSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

interface AccessServerVenue {
  name: string;
}

interface AccessServerGates {
  id: number;
  name: string;
  type: string;
}

const getAccessServers = async (
  query: string | null,
  venue_id: string | number | null,
  page: number,
  results: number
) => {
  const { data }: any = await instance.get(
    // `/api/v1/access_servers?query=${query}&venue_id=${venue_id}&page=${page}&results=${results}`,
    `/api/v1/access_servers?query=${query}&page=${page}&results=${results}`,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as AccessServersResponse;
};
//-------------------------------------------------------

//End point (Access Servers): Get access server by id - GET

export interface AccessServerResponse {
  access_server: AccessServerRes;
}

export interface AccessServerRes {
  id: number;
  name: string;
  address: string;
  longitude: string;
  latitude: string;
  settings: AccessServerSettings;
  venue: AccessServerVenue;
  gates: AccessServerGates[];
}

interface AccessServerSettings {
  photo_id: boolean;
  other: boolean;
  back_plates: boolean;
  front_plates: boolean;
  accreditation: boolean;
}

interface AccessServerVenue {
  name: string;
}

interface AccessServerGates {
  id: number;
  name: string;
  type: string;
  data_print_response: boolean;
  remote_pulse: boolean;
}

const getAccessServerById = async (id: number | null) => {
  const { data }: any = await instance.get(`/api/v1/access_servers/${id}`, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as AccessServerResponse;
};
//-------------------------------------------------------
//End point (Network Groups): create - POST
export interface CreateNetworkGroupRequest {
  network_group: NetworkGroupRequest;
}
export interface NetworkGroupRequest {
  access_servers: number[];
  name: string;
}

const createNetworkGroup = async (params: CreateNetworkGroupRequest) => {
  const { data }: any = await instance.post(`/api/v1/network_groups`, params, {
    headers: {
      Authorization: `Token=${localStorage.getItem("token")}`,
    },
  });
  return data as NetworkGroups;
};

//-------------------------------------------------------
//End point (Network Groups): update - PATCH

const updateNetworkGroup = async (
  id: number,
  params: CreateNetworkGroupRequest
) => {
  const { data }: any = await instance.patch(
    `/api/v1/network_groups/${id}`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as NetworkGroups;
};

//-------------------------------------------------------
//End point (Access Server): update - PATCH

export interface UpdateAccessServerParams {
  access_server: {
    name: string;
  };
  settings: {
    photo_id: boolean;
    other: boolean;
    back_plates: boolean;
    front_plates: boolean;
    accreditation: boolean;
  };
}

export interface UpdateAccessServerResponse {
  id: number;
  name: string;
  address: string;
  longitude: string;
  latitude: string;
  settings: {
    photo_id: boolean;
    other: boolean;
    back_plates: boolean;
    front_plates: boolean;
    accreditation: boolean;
  };
  venue: {
    name: string;
  };
  gates: [
    {
      id: number;
      name: string;
      type: string;
    }
  ];
}

const updateAccessServer = async (
  id: number,
  params: UpdateAccessServerParams
) => {
  const { data }: any = await instance.patch(
    `/api/v1/access_servers/${id}`,
    params,
    {
      headers: {
        Authorization: `Token=${localStorage.getItem("token")}`,
      },
    }
  );
  return data as UpdateAccessServerResponse;
};

//-------------------------------------------------------
//STRAPI USER----------------------------------------------->>

const createStrapiUser = async (params: StrapiUserPayload) => {
  const { data }: { data: StrapiUser } = await instance_strapi.post(
    `/api/users`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strapi_token}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//STRAPI USER------------------------------------------------->>

const getStrapiUserById = async (id: number) => {
  const { data }: { data: StrapiUser[] } = await instance_strapi.get(
    `/api/users?populate=*&filters[id_legacy][$eq]=${id}`,
    {
      headers: {
        Authorization: `Bearer ${strapi_token}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//LOGIN------------------------------------------------------->>

export interface StrapiLoginPayload {
  identifier: string;
  password: string;
}

export interface StrapiLoginResponse {
  jwt: string;
  user: StrapiUser;
}

const strapiLoginRequest = async (params: StrapiLoginPayload) => {
  const { data }: { data: StrapiLoginResponse } = await instance_strapi.post(
    "/api/auth/local",
    params
  );

  return data;
};

//------------------------------------------------------------>>
//GET USER DATA----------------------------------------------->>

const strapiUserByIdRequest = async (userId: number, token: string) => {
  const { data }: { data: StrapiUser } = await instance_strapi.get(
    `/api/users/${userId}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET UNIT DATA----------------------------------------------->>

const strapiUnitByIdRequest = async (unitId: number, token: string) => {
  const { data }: { data: StrapiUnit } = await instance_strapi.get(
    `/api/units/${unitId}?populate[0]=property`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET PROPERTY DATA------------------------------------------->>

const strapiPropertyByIdRequest = async (propertyId: number, token: string) => {
  const { data }: { data: StrapiProperty } = await instance_strapi.get(
    `/api/properties/${propertyId}?populate[0]=company`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET USER DATA----------------------------------------------->>

const strapiUsersByUnitRequest = async (
  unitId: number,
  roleId1: number,
  roleId2?: number
) => {
  let roleFilter = `filters[role][id][$in][0]=${roleId1}`;
  if (roleId2) {
    roleFilter += `&filters[role][id][$in][1]=${roleId2}`;
  }
  const { data }: { data: StrapiUser[] } = await instance_strapi.get(
    `/api/users?populate=*&filters[unit][id][$eq]=${unitId}&${roleFilter}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET USER DATA----------------------------------------------->>

const strapiUsersRequest = async (
  propertyId: number,
  roleId1: number,
  roleId2?: number
) => {
  let roleFilter = `filters[role][id][$in][0]=${roleId1}`;
  if (roleId2) {
    roleFilter += `&filters[role][id][$in][1]=${roleId2}`;
  }
  const { data }: { data: StrapiUser[] } = await instance_strapi.get(
    `/api/users?populate=*&filters[unit][property][id][$eq]=${propertyId}&${roleFilter}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET UNIT DATA----------------------------------------------->>

const strapiUnitsByPropertyRequest = async (propertyId: number) => {
  const { data }: { data: StrapiUnitArray } = await instance_strapi.get(
    `/api/units?populate=*&filters[property][id][$eq]=${propertyId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET ACCESS POINT DATA--------------------------------------->>

const strapiAccessPointsByPropertyRequest = async (propertyId: number) => {
  const { data }: { data: StrapiAccessPointArray } = await instance_strapi.get(
    `/api/access-points?populate=*&filters[property][id][$eq]=${propertyId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET ACCESS POINT DATA--------------------------------------->>

const strapiAccessPointsByIdRequest = async (accessPointId: number) => {
  const { data }: { data: StrapiAccessPointRelation } =
    await instance_strapi.get(
      `/api/access-points/${accessPointId}?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

  return data;
};

//------------------------------------------------------------>>
//UPDATE ACCESS POINT DATA------------------------------------>>

export interface AccessPointPayload {
  data: AccessPointPayloadData;
}

interface AccessPointPayloadData {
  name?: string;
  photo_types?: number[];
}

const strapiUpdateAccessPointsByIdRequest = async (
  accessPointId: number,
  params: AccessPointPayload
) => {
  const { data }: { data: StrapiAccessPointRelation } =
    await instance_strapi.put(`/api/access-points/${accessPointId}`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

  return data;
};

//------------------------------------------------------------>>
//UNITS------------------------------------------------------->>

export interface UnitPayload {
  data: UnitPayloadData;
}

interface UnitPayloadData {
  name?: string;
  property?: number;
  access_points?: number[];
}

const strapiCreateUnit = async (params: UnitPayload) => {
  const { data }: { data: StrapiUnit } = await instance_strapi.post(
    `/api/units`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//UNITS------------------------------------------------------->>

const strapiUpdateUnit = async (unitId: number, params: UnitPayload) => {
  const { data }: { data: StrapiUnit } = await instance_strapi.put(
    `/api/units/${unitId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET ACCESS POINT DATA--------------------------------------->>

const strapiPhotoTypesRequest = async () => {
  const { data }: { data: StrapiPhotoTypesArray } = await instance_strapi.get(
    `/api/photo-types`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//UNITS------------------------------------------------------->>

export interface StrUserPayload {
  username?: string;
  email?: string;
  phone?: string;
  role?: number;
  unit?: number;
  password?: string;
  access_points?: number[];
}

const strapiCreateUser = async (params: StrUserPayload) => {
  const { data }: { data: StrapiUser } = await instance_strapi.post(
    `/api/auth/local/register`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET ORGANIZATIONAL AREAS DATA------------------------------->>

const strapiOrganizationalAreasRequest = async () => {
  const { data }: { data: StrapiOrganizationalAreasArray } =
    await instance_strapi.get(`/api/organizational-areas?populate=*`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

  return data;
};

//------------------------------------------------------------>>
//GET STRAPI ACCESS KEYS DATA------------------------------->>

const strapiAccessKeysByUserRequest = async (userId: number) => {
  const { data }: { data: StrpiAccessKeyArray } = await instance_strapi.get(
    `/api/access-keys?populate=*&filters[created][id][$eq]=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//GET STRAPI ACCESS KEYS DATA------------------------------->>

const strapiAccessKeysByIdRequest = async (accessKeyId: number) => {
  const { data }: { data: StrpiAccessKey } = await instance_strapi.get(
    `/api/access-keys/${accessKeyId}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//STRAPI ACCESS KEYS------------------------------------------>>

export interface StrAccessKeyPayload {
  data: {
    valid_from?: string;
    valid_until?: string;
    reason_for_visit?: string;
    created?: number;
    event?: number;
    recurrent_visitor?: number;
    one_time_visitor?: string;
  };
}

const strapiCreateAccessKey = async (params: StrAccessKeyPayload) => {
  const { data }: { data: StrpiAccessKeyRelation } = await instance_strapi.post(
    `/api/access-keys`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//STRAPI ACCESS KEYS------------------------------------------>>

export interface StrOneTimeVisitorPayload {
  data: {
    name?: string;
    email?: string;
    phone?: string;
    provider?: number;
    access_group?: number;
    access_key?: number;
  };
}

const strapiCreateOneTimeVisitor = async (params: StrOneTimeVisitorPayload) => {
  const { data }: { data: StrapiOneTimeVisitorRelation } = await instance_strapi.post(
    `/api/access-keys`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>

const api = {
  loginRequest,
  logbookRequest,
  invitationsRequest,
  registerVisitRequest,
  usersListRequest,
  // validateKeyRequest,
  validateKeyRequestNoTransaction,
  changeAccessServerRequest,
  logExitRegisterRequest,
  accessKeysAddPhotosRequest,
  transactionIdRequest,
  invitationIdRequest,
  createAccessKey,
  getCommunityMembersList,
  usersByNetworkRequest,
  createUser,
  deleteUser,
  editUser,
  eventListRequest,
  resetPassword,
  logbookRequestTwo,
  communityMembersRequest,
  createEvent,
  providersRequest,
  eventAccessKeysRequest,
  editVisitInvitation,
  editEvent,
  getEventId,
  getDynamicKey,
  deleteAccessKey,
  addEventGuestRequest,
  getInvitationCustomAttributes,
  getRegisterCustomAttributes,
  getGuestsInsideAccessServer,
  openGate,
  getNetworkGroups,
  getAccessServers,
  getAccessServerById,
  createNetworkGroup,
  updateNetworkGroup,
  updateAccessServer,
  createStrapiUser,
  getStrapiUserById,
  strapiLoginRequest,
  strapiUserByIdRequest,
  strapiUnitByIdRequest,
  strapiPropertyByIdRequest,
  strapiUsersByUnitRequest,
  strapiUnitsByPropertyRequest,
  strapiAccessPointsByPropertyRequest,
  strapiUsersRequest,
  strapiCreateUnit,
  strapiUpdateUnit,
  strapiPhotoTypesRequest,
  strapiCreateUser,
  strapiAccessPointsByIdRequest,
  strapiUpdateAccessPointsByIdRequest,
  strapiOrganizationalAreasRequest,
  strapiAccessKeysByUserRequest,
  strapiCreateAccessKey,
  strapiCreateOneTimeVisitor,
  strapiAccessKeysByIdRequest,
};

export default api;
