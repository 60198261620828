// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.id {
  width: 95%;
}

.plate {
  width: 98%;
}

.person {
  width: 90%;
}

.computer {
  width: 98%;
}

.back-id {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/PhotoModal/photoModal.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".id {\n    width: 95%;\n}\n\n.plate {\n    width: 98%;\n}\n\n.person {\n    width: 90%;\n}\n\n.computer {\n    width: 98%;\n}\n\n.back-id {\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
