// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-main-container {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 176px;
  left: 0;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
  animation: in 0.2s ease-out;
  z-index: 96;
}

.filters-main-container-out {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 126px;
  left: 0;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
  animation: out 0.2s ease-out;
  animation-play-state: running;
  z-index: 96;
}

@keyframes in {
  from {
    top: 126px;
  }
  to {
    top: 176px;
  }
}
@keyframes out {
  from {
    top: 176px;
  }
  to {
    top: 126px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/filters.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,OAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gCAAA;EACA,2BAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,OAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gCAAA;EACA,4BAAA;EACA,6BAAA;EACA,WAAA;AACF;;AAEA;EACE;IAAM,UAAA;EAEN;EADA;IAAI,UAAA;EAIJ;AACF;AAFA;EACE;IAAM,UAAA;EAKN;EAJA;IAAI,UAAA;EAOJ;AACF","sourcesContent":[".filters-main-container {\n  width: 100vw;\n  height: 60px;\n  position: fixed;\n  top: 176px;\n  left: 0;\n  background-color: #e8e8e8;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px #d9d9d9 solid;\n  animation: in 0.2s ease-out;\n  z-index: 96;\n}\n\n.filters-main-container-out {\n  width: 100vw;\n  height: 60px;\n  position: fixed;\n  top: 126px;\n  left: 0;\n  background-color: #e8e8e8;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px #d9d9d9 solid;\n  animation: out 0.2s ease-out;\n  animation-play-state: running;\n  z-index: 96;\n}\n\n@keyframes in {\n  from {top: 126px;}\n  to {top: 176px;}\n}\n\n@keyframes out {\n  from {top: 176px;}\n  to {top: 126px;}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
